import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill } from "react-icons/pi";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import Alerts from "../../components/Alerts.jsx";
import { IContext } from "../../context/IntegrateContext.jsx";
import * as mutations from "../../graphql/mutations";
import { API } from "aws-amplify";

export default function UploadTemplateS2({ user, signOut }) {
  const [alert, setAlert] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [programType, setProgramType] = useState("");

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handleTemplateTypeChange = (event) => {
    setTemplateType(event.target.value);
  };
  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };
  const handleProgramTypeChange = (event) => {
    setProgramType(event.target.value);
  };

  let numberStylesOn = { padding: "2px", color: "#0000A3", fontSize: "30px" };
  let numberStylesOut = { padding: "2px", color: "#AEB3B7", fontSize: "30px" };

  const { templateAndbenefitprogramTypeList, tempCompanyNames } = IContext();

  let companyNameList = [];
  for (var cn of tempCompanyNames) {
    let tempCompanyName = {
      company_id: "",
      company_name: "",
    };
    tempCompanyName.company_id = cn.company_id;
    tempCompanyName.company_name = cn.company_name;
    companyNameList.push(tempCompanyName);
  }

  let tempTemplateTypeList = [];
  for (var t2 of templateAndbenefitprogramTypeList) {
    if (
      t2.category_name === "eform_create" &&
      t2.attribute_key_name === "eform_type"
    ) {
      let temp3 = {
        template_id: "",
        template_display_name: "",
        template_sort_order: "",
      };
      temp3.template_id = t2.attribute_list_valid_value;
      temp3.template_display_name = t2.attribute_list_value_display_name;
      temp3.template_sort_order = t2.attribute_list_value_sort_order;
      tempTemplateTypeList.push(temp3);
    } else {
    }
  }

  let templateTypeList = [];
  const uniqueMap3 = new Map();
  for (const obj of tempTemplateTypeList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap3.has(objString)) {
      uniqueMap3.set(objString, true);
      templateTypeList.push(obj);
    }
  }

  let temBenefitProgramTypeList = [];
  for (var t3 of templateAndbenefitprogramTypeList) {
    if (
      t3.category_name === "eform_create" &&
      t3.attribute_key_name === "eform_benefit_id"
    ) {
      let temp3 = {
        template_id: "",
        template_display_name: "",
        template_sort_order: "",
      };
      temp3.template_id = t3.attribute_list_valid_value;
      temp3.template_display_name = t3.attribute_list_value_display_name;
      temp3.template_sort_order = t3.attribute_list_value_sort_order;
      temBenefitProgramTypeList.push(temp3);
    } else {
    }
  }

  let benefitProgramTypeList = [];
  const uniqueMap4 = new Map();
  for (const obj of temBenefitProgramTypeList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap4.has(objString)) {
      uniqueMap4.set(objString, true);
      benefitProgramTypeList.push(obj);
    }
  }

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "40px" };

  let navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("uploadValueCompanyName", JSON.stringify(companyName));
    localStorage.setItem(
      "uploadValueTemplateName",
      JSON.stringify(templateName),
    );
    localStorage.setItem(
      "uploadValueTemplateType",
      JSON.stringify(templateType),
    );
    localStorage.setItem(
      "uploadValueBenefitProgramType",
      JSON.stringify(programType),
    );
  });

  const handleSave = async (e) => {
    if (
      companyName < 1 ||
      templateName < 1 ||
      templateType < 1 ||
      programType < 1
    ) {
      e.preventDefault();
      setAlert("error");
    } else {
      e.preventDefault();

      const sendingBenefitStep1Values = await API.graphql({
        query: mutations.createeForm,
        variables: {
          template_display_name: templateName,
          template_status: "A",
          template_type_id: templateType,
          benefit_id: programType,
          attribute_key: "",
          attribute_value: "",
          userid: user,
        },
      }).then((data) => {
        console.log(data);
      });

      setAlert("success");
      navigate("/integrate/uploadtemplateS3");
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
            input[type="checkbox"]{accent-color:#0000A3;}
          `}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[15rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/upload-select">
              Upload
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/uploadtemplateS2">
              E-Forms
            </Link>
          </div>
          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <MdOutlineAutoFixHigh style={iconStyles} />
              <p className="text-3xl mx-1">Create E-Form</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Successfully, Proceed to upload the file in next page"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Please fill all the fields" />
            ) : (
              ""
            )}
          </div>
          {/* form*/}
          <div className="flex mt-5 ">
            <div className="ml-3">
              <div className="flex items-center space-x-2">
                <PiNumberCircleOneFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 1</p>
                  <p className="font-bold text-md pb-1 ">Select E-Form Type</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiNumberCircleTwoFill style={numberStylesOut} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 2</p>
                  <p className=" text-md pb-1 ">Create E-Form Template</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>{" "}
              </div>
            </div>

            <div className="w-[615px] ml-16 mt-4">
              <Paper elevation={2}>
                <div className="px-10 pt-10">
                  <div className="flex justify-center">
                    <div className="font-medium text-2xl">E-Form Details</div>
                  </div>
                  <form className="ml-5 grid grid-cols-2 gap-8 mt-16">
                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS22">
                        Company Name
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS23"
                        id="UploadTemplateS24"
                        value={companyName}
                        label="Company Name"
                        onChange={handleCompanyNameChange}
                      >
                        {companyNameList.map((option, key) => (
                          <MenuItem value={option.company_id} key={key}>
                            {option.company_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl name="fullWidth12" fullWidth>
                      <TextField
                        id="UploadTemplateS25"
                        value={templateName}
                        label="Template Name"
                        onChange={handleTemplateNameChange}
                        variant="outlined"
                      />
                    </FormControl>

                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS26">
                        Template Type
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS27"
                        id="UploadTemplateS28"
                        value={templateType}
                        label="Template Type"
                        onChange={handleTemplateTypeChange}
                      >
                        {templateTypeList.map((option, key) => (
                          <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS29">
                        Benefit Program Type
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS30"
                        id="UploadTemplateS31"
                        value={programType}
                        label="Benefit Program Type"
                        onChange={handleProgramTypeChange}
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: 250 } },
                        }}
                      >
                        {benefitProgramTypeList.map((option, key) => (
                          <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <div className="pt-16 pb-12 col-start-1 col-end-3 w-full flex justify-end items-center">
                      <button
                        onClick={(e) => {
                          handleClear(e);
                        }}
                        className="ml-10 bg-gray-100 rounded-md text-gray-600 shadow-black shadow-[0_1px_4px_rgb(0,0,0,0.2)] py-[6px] px-5"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-5 bg-[#DB2748] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                        onClick={(e) => {
                          handleSave(e);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>

                {/* <div>{companyName}</div>
                <div>{templateType}</div>
                <div>{templateName}</div>
                <div>{programType}</div> */}
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// let tempTemplateTypeList = [];
// for (var t of masterJson) {
//   if (t.company_id === companyName && t.template_type_id !== null) {
//     let temp1 = {
//       template_type_id: "",
//       template_type_display_name: "",
//     };
//     temp1.template_type_id = t.template_type_id;
//     temp1.template_type_display_name = t.template_type_display_name;
//     tempTemplateTypeList.push(temp1);
//   } else {
//   }
// }
//
// let templateTypeList = [];
// const uniqueMap = new Map();
// for (const obj of tempTemplateTypeList) {
//   const objString = JSON.stringify(obj);
//   if (!uniqueMap.has(objString)) {
//     uniqueMap.set(objString, true);
//     templateTypeList.push(obj);
//   }
// }
//
//
// console.log(tempTemplateTypeList);
// /eform_type eform_benefit_id/
//eform_create
//category_name
// console.log(benefitProgramTypeList);
// console.log(localStorage.getItem("uploadValueCompanyName"));
// console.log(localStorage.getItem("uploadValueTemplateName"));
// console.log(localStorage.getItem("uploadValueTemplateType"));
// console.log(localStorage.getItem("uploadValueBenefitProgramType"));
//
