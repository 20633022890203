import { React, useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { PiCheckCircleFill, PiNumberCircleTwoFill } from "react-icons/pi";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import Alerts from "../../components/Alerts.jsx";
import { MdCloudUpload } from "react-icons/md";
// import { IContext } from "../../context/IntegrateContext.jsx";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API } from "aws-amplify";

export default function EFormUploadS2({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [alert, setAlert] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [date, setDate] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [fileList, setFileList] = useState([]);
  const [invoiceRowList, setInvoiceRowList] = useState([]);
  const [headcountRowList, setHeadcountRowList] = useState([]);
  const [vendorPerformanceRowList, setVendorPerformanceRowList] = useState([]);
  const [medicalRowList, setMedicalRowList] = useState([]);
  const [generalUtlizationRowList, setGeneralUtlizationRowList] = useState([]);

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handleTemplateTypeChange = (event) => {
    setTemplateType(event.target.value);
  };
  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  let displayTemplateType = JSON.parse(
    localStorage.getItem("eformTemplateType"),
  );

  let displayTemplateName = JSON.parse(
    localStorage.getItem("eformTemplateName"),
  );

  let displayCompanyName = JSON.parse(localStorage.getItem("eformCompanyName"));

  console.log(displayTemplateType);

  let numberStylesOn = { padding: "2px", color: "#0000A3", fontSize: "30px" };
  let numberStylesOut = { padding: "2px", color: "#AEB3B7", fontSize: "30px" };

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "40px" };

  let navigate = useNavigate();

  const invoiceRows = async () => {
    const list = await API.graphql({
      query: mutations.geteFormTemplateFilterInvoice,
      variables: {
        template_id: "1234567",
      },
    });
    // console.log(templateAndbenefitprogramSelection.data);integratecon
    setInvoiceRowList(list.data.geteFormTemplateFilterInvoice);
    setWait(false);
  };
  // console.log(invoiceRowList)

  const headcountRows = async () => {
    const list = await API.graphql({
      query: mutations.geteFormTemplateHeadcount,
      variables: {
        template_id: "general_headcount",
      },
    }).then((data) => console.log(data));
    // console.log(templateAndbenefitprogramSelection.data);integratecon
    // console.log(list.data.geteFormTemplateHeadcount);
    setHeadcountRowList(list);
    setWait(false);
  };

  useEffect(() => {
    invoiceRows();
  }, []);
  console.log(invoiceRowList);

  const vendorPerformanceRows = async () => {
    const list = await API.graphql({
      query: mutations.geteFormTemplateOperationalPerformance,
      variables: {
        template_id: "general_invoice_template",
      },
    });
    // console.log(templateAndbenefitprogramSelection.data);integratecon
    console.log(list.data.geteFormTemplateOperationalPerformance);
    setWait(false);
  };

  // const mediclRows = async () => {
  //   const list = await API.graphql({
  //     query: mutations.geteFormTemplateMedicalDiagnosis,
  //     variables: {
  //       template_id: "general_invoice_template",
  //     },
  //   });
  //   // console.log(templateAndbenefitprogramSelection.data);integratecon
  //   console.log(list.data.geteFormTemplateMedicalDiagnosis);
  //   setWait(false);
  // };

  const generalUtlizationRows = async () => {
    const list = await API.graphql({
      query: mutations.geteFormTemplateGeneralUsage,
      variables: {
        template_id: "general_invoice_template",
      },
    });
    // console.log(templateAndbenefitprogramSelection.data);integratecon
    console.log(list.data.geteFormTemplateGeneralUsage);
    setWait(false);
  };

  // useEffect(() => {
  //   if (displayTemplateType === "general_invoice_template") {
  //     invoiceRows();
  //   } else if (displayTemplateType === "general_headcount") {
  //     headcountRows();
  //   } else if (displayTemplateType === "general_operational_performance") {
  //     vendorPerformanceRows();
  //   } else if (displayTemplateType === "general_utilization") {
  //     generalUtlizationRows();
  //   }
  //     // else if (displayTemplateType === "") {
  //   //   mediclRows();
  //   // }
  //   return () => {};
  // }, []);

  const columns1 = [
    { field: "id", headerName: "ID", width: 20 },
    { field: "as_of_date", headerName: "Date", width: 100 },
    { field: "template_display_name", headerName: "Template Name", width: 100 },
    {
      field: "template_type_display_name",
      headerName: "Template Type",
      width: 100,
    },
    { field: "company_name", headerName: "Company Name", width: 100 },
    { field: "benefit_display_name", headerName: "Benefit Name", width: 100 },
    { field: "country_display_name", headerName: "Country", width: 100 },
    { field: "vendor", headerName: "Vendor", width: 100 },
    {
      field: "cost_category_display_name",
      headerName: "Cost Category",
      width: 100,
    },
    { field: "sub_plan_id_display_name", headerName: "Sub Plan", width: 100 },
    { field: "cost_type_display_name", headerName: "Cost Type", width: 100 },
    { field: "currency_code", headerName: "Currency", width: 100 },
    {
      field: "amount",
      type: "string",
      headerName: "Amount",
      width: 100,
      editable: true,
      cellClassName: (params) => {
        if (params.value === null || params.value === "") {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
      },
    },
    { field: "userid", headerName: "User", width: 100 },
    {
      field: "administrator_comments",
      type: "string",
      headerName: "Admin Comments",
      width: 250,
      editable: true,
      cellClassName: (params) => {
        if (params.value === null || params.value === "") {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
      },
    },
  ];

  const columns2 = [
    { field: "template_display_name", headerName: "Template Name", width: 100 },
    {
      field: "template_type_display_name",
      headerName: "Template Type",
      width: 100,
    },
    { field: "company_name", headerName: "Company Name", width: 100 },
    { field: "benefit_display_name", headerName: "Benefit Name", width: 100 },
    { field: "country_display_name", headerName: "Country", width: 100 },
    { field: "vendor", headerName: "Vendor", width: 100 },
    {
      field: "headcount_type_display_name",
      headerName: "Headcount Type",
      width: 100,
    },
    {
      field: "amount",
      type: "string",
      headerName: "Amount",
      width: 100,
      editable: true,
      cellClassName: (params) => {
        if (params.value === null || params.value === "") {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
      },
    },
    {
      field: "administrator_comments",
      type: "string",
      headerName: "Admin Comments",
      width: 250,
      editable: true,
      cellClassName: (params) => {
        if (params.value === null || params.value === "") {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
      },
    },
    { field: "userid", headerName: "User", width: 100 },
  ];

  const columns3 = [
    { field: "template_display_name", headerName: "Template Name", width: 100 },
    {
      field: "template_type_display_name",
      headerName: "Template Type",
      width: 100,
    },
    { field: "company_name", headerName: "Company Name", width: 100 },
    { field: "benefit_display_name", headerName: "Benefit Name", width: 100 },
    { field: "country_display_name", headerName: "Country", width: 100 },
    { field: "vendor", headerName: "Vendor", width: 100 },
    { field: "sla_description", headerName: "SLA Description", width: 100 },
    { field: "sla_value", headerName: "SLA Value", width: 100 },
    {
      field: "administrator_comments",
      type: "string",
      headerName: "Admin Comments",
      width: 250,
      editable: true,
      cellClassName: (params) => {
        // params.value.length <= 0 || params.value.length === null
        if (params.value === null || params.value === "") {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
        // console.log(params.value.length);
      },
    },
    { field: "userid", headerName: "User", width: 100 },
  ];

  const columns4 = [
    { field: "template_display_name", headerName: "Template Name", width: 100 },
    {
      field: "template_type_display_name",
      headerName: "Template Type",
      width: 100,
    },
    { field: "company_name", headerName: "Company Name", width: 100 },
    { field: "benefit_display_name", headerName: "Benefit Name", width: 100 },
    { field: "country_display_name", headerName: "Country", width: 100 },
    { field: "vendor", headerName: "Vendor", width: 100 },
    { field: "metric_display_name", headerName: "Metric", width: 100 },
    { field: "usage_type_display_name", headerName: "Usage Type", width: 100 },
    { field: "spend", headerName: "Spend", width: 100 },
    { field: "claim_volume", headerName: "Claim Volume", width: 100 },
    {
      field: "administrator_comments",
      type: "string",
      headerName: "Admin Comments",
      width: 250,
      editable: true,
      cellClassName: (params) => {
        // params.value.length <= 0 || params.value.length === null
        if (params.value === null || params.value === "") {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
        // console.log(params.value.length);
      },
    },
    { field: "userid", headerName: "User", width: 100 },
  ];

  const columns5 = [
    { field: "as_of_date", headerName: "Date", width: 100 },
    { field: "template_display_name", headerName: "Template Name", width: 100 },
    {
      field: "template_type_display_name",
      headerName: "Template Type",
      width: 100,
    },
    { field: "company_name", headerName: "Company Name", width: 100 },
    { field: "benefit_display_name", headerName: "Benefit Name", width: 100 },
    { field: "country_display_name", headerName: "Country", width: 100 },
    { field: "vendor", headerName: "Vendor", width: 100 },
    {
      field: "cost_category_display_name",
      headerName: "Cost Category",
      width: 100,
    },
    { field: "sub_plan_id_display_name", headerName: "Sub Plan", width: 100 },
    { field: "cost_type_display_name", headerName: "Cost Type", width: 100 },
    { field: "currency_code", headerName: "Currency", width: 100 },
    {
      field: "amount",
      type: "string",
      headerName: "Amount",
      width: 100,
      editable: true,
      cellClassName: (params) => {
        if (params.value === null || params.value === "") {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
      },
    },
    { field: "userid", headerName: "User", width: 100 },
    {
      field: "administrator_comments",
      type: "string",
      headerName: "Admin Comments",
      width: 250,
      editable: true,
      cellClassName: (params) => {
        // params.value.length <= 0 || params.value.length === null
        if (params.value === null || params.value === "") {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
        // console.log(params.value.length);
      },
    },
  ];

  useEffect(() => {
    localStorage.setItem("uploadValueCompanyName", JSON.stringify(companyName));
    localStorage.setItem(
      "uploadValueTemplateName",
      JSON.stringify(templateName),
    );
    localStorage.setItem(
      "uploadValueTemplateType",
      JSON.stringify(templateType),
    );
  });

  const handleSave = async (e) => {
    e.preventDefault();
    if (companyName < 1 || templateName < 1 || templateType < 1) {
      e.preventDefault();
      setAlert("error");
    } else {
      e.preventDefault();
      setAlert("success");
      // navigate("/integrate/uploadtemplateS3");
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log(newRow);
    // editedRows.push(newRow);
    // console.log(editedRows);
    // let unEditedRows = backendRows.filter((row) => row.id !== newRow.id);
    // unEditedRows.push(updatedRow);
    // backendRows = unEditedRows;
    // console.log(backendRows);
    return updatedRow;
  };

  const handleClear = (e) => {
    e.preventDefault();
  };

  let fileStyles = {
    color: "#0000A3",
    fontSize: "50px",
    margin: "auto",
  };

  const ddRef = useRef(null);
  const onFileDrop = (e) => {
    const files = e.target.files[0];
    if (files) {
      const updatedList = [files];
      setFileList(updatedList);
      // console.log(updatedList);
    }
  };

  console.log(fileList);

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
            input[type="checkbox"]{accent-color:#0000A3;}
          `}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[3rem] overflow-x-scroll">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/upload-select">
              Select Upload Type
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/uploadtemplateS2">
              E-Forms
            </Link>
          </div>
          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <MdOutlineAutoFixHigh style={iconStyles} />
              <p className="text-3xl mx-1">E-Form</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Successfully, Proceed to upload the file in next page"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Please fill all the fields" />
            ) : (
              ""
            )}
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>
          {/* form*/}
          <div className="flex mt-5 ">
            <div className="ml-3">
              <div className="flex items-center space-x-2">
                <PiCheckCircleFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className="text-gray-600">Step 1</p>
                  <p className="text-md pb-1 ">Select Template Type</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiNumberCircleTwoFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 2</p>
                  <p className="font-bold text-md pb-1 ">Complete E-Form</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>{" "}
              </div>
            </div>

            <div className="w-fit ml-16 mt-4">
              <Paper elevation={2}>
                <div className="px-10 pt-10">
                  <div className="text-xl font-bold uppercase">
                    <div>{displayTemplateType}</div>
                  </div>
                  <div className="flex justify-center">
                    <div className="font-medium text-2xl"></div>
                  </div>
                  <form
                    className="flex flex-col justify-center" // ml-5 grid grid-cols-2 gap-8 mt-16
                  >
                    <div className="grid grid-cols-2 gap-8 mt-16">
                      <FormControl name="fullWidth2" fullWidth>
                        <DatePicker
                          label="Report Start Date"
                          name="date"
                          value={date}
                          onChange={(date) => setDate(date)}
                          format="YYYY-DD-MM"
                        />
                      </FormControl>

                      <FormControl name="fullWidth2" fullWidth>
                        <DatePicker
                          label="Report End Date"
                          name="date"
                          value={renewalDate}
                          onChange={(date) => setRenewalDate(date)}
                          format="YYYY-DD-MM"
                        />
                      </FormControl>
                    </div>

                    <div>
                      <div className="mt-10 w-full">
                        <DataGrid
                          style={{
                            maxHeight: "450px",
                            maxWidth: "1000px",
                          }}
                          rowHeight={30}
                          rows={
                            displayTemplateType === "general_invoice_template"
                              ? invoiceRowList.map((item, index) => ({
                                  id: index + 1,
                                  ...item,
                                }))
                              : displayTemplateType === "general_headcount"
                              ? headcountRowList
                              : displayTemplateType ===
                                "general_operational_performance"
                              ? vendorPerformanceRowList
                              : displayTemplateType === "general_utilization"
                              ? generalUtlizationRowList
                              : ""
                          }
                          // getRowId={(row) =>}
                          // rows={rows.map((item, index) => ({ id: index + 1, ...item }))}
                          columns={
                            displayTemplateType === "general_invoice_template"
                              ? columns1
                              : displayTemplateType === "general_headcount"
                              ? columns2
                              : displayTemplateType ===
                                "general_operational_performance"
                              ? columns3
                              : displayTemplateType === "general_utilization"
                              ? columns4
                              : ""
                          }
                          processRowUpdate={processRowUpdate}
                          onProcessRowUpdateError={(error) =>
                            console.log(error)
                          }
                          stopEditingWhenCellsLoseFocus={true}
                          onCellEditStop={(params, event) => {
                            if (
                              params.reason ===
                              GridCellEditStopReasons.cellFocusOut
                            ) {
                              event.defaultMuiPrevented = true;
                            }
                            if (
                              params.reason ===
                              GridCellEditStopReasons.escapeKeyDown
                            ) {
                              event.defaultMuiPrevented = true;
                            }
                            if (
                              params.reason ===
                              GridCellEditStopReasons.tabKeyDown
                            ) {
                              event.defaultMuiPrevented = true;
                            }
                            if (
                              params.reason ===
                              GridCellEditStopReasons.shiftTabKeyDown
                            ) {
                              event.defaultMuiPrevented = true;
                            }
                          }}
                          sx={{
                            "& .empty": {
                              backgroundColor: "#FCF3F2",
                              color: "#CC301A",
                              fontWeight: "600",
                            },
                            "& .fill": {
                              backgroundColor: "#EBF7DA",
                              color: "#2E5C0E",
                              fontWeight: "600",
                            },
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="col-span-2 mt-16">
                        <div className="h-fit">
                          <p className="text-sm py-1 pb-2 text-gray-400">
                            Drag and drop files you want to upload here, or
                            click below.
                          </p>
                          <div
                            ref={ddRef}
                            className="relative grid w-full h-[116px] justify-center items-center hover:opacity-70 border-2 border-dotted cursor-pointer"
                          >
                            <MdCloudUpload style={fileStyles} />
                            <p className="text-[#0000A3]">
                              Upload template or drag and Drop
                            </p>
                            <input
                              type="file"
                              className="opacity-0 absolute h-full w-full cursor-pointer"
                              // value={""}
                              onChange={onFileDrop}
                              name="uploadedFiles"
                              // accept=".xls,.xlsx,.txt,.csv"
                              accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, text/plain,.csv"
                              accepts={[".xls", ".xlsx", ".txt", ".csv"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-16 pb-12 col-start-1 col-end-3 w-full flex justify-end items-center">
                      <button
                        onClick={(e) => {
                          handleClear(e);
                        }}
                        className="ml-10 bg-gray-100 rounded-md text-gray-600 shadow-black shadow-[0_1px_4px_rgb(0,0,0,0.2)] py-[6px] px-5"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-5 bg-[#0000A3] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-7  text-white"
                        onClick={(e) => {
                          handleSave(e);
                        }}
                      >
                        {" "}
                        Submit{" "}
                      </button>
                    </div>
                  </form>
                </div>
                <div>{companyName}</div>
                <div>{templateType}</div>
                <div>{templateName}</div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// const rows = [
//   {
//     id: 1,
//     column1: "data 1",
//     column2: "data 2",
//     column3: "data 3",
//     column4: "data 4",
//     column5: "data 5",
//     administrator_comments: "",
//   },
//   {
//     id: 2,
//     column1: "data 1",
//     column2: "data 2",
//     column3: "data 3",
//     column4: "data 4",
//     column5: "data 5",
//     administrator_comments: "",
//   },
//   {
//     id: 3,
//     column1: "data 1",
//     column2: "data 2",
//     column3: "data 3",
//     column4: "data 4",
//     column5: "data 5",
//     administrator_comments: "",
//   },
//   {
//     id: 4,
//     column1: "data 1",
//     column2: "data 2",
//     column3: "data 3",
//     column4: "data 4",
//     column5: "data 5",
//     administrator_comments: "",
//   },
//   {
//     id: 5,
//     column1: "data 1",
//     column2: "data 2",
//     column3: "data 3",
//     column4: "data 4",
//     column5: "data 5",
//     administrator_comments: "",
//   },
//   {
//     id: 6,
//     column1: "data 1",
//     column2: "data 2",
//     column3: "data 3",
//     column4: "data 4",
//     column5: "data 5",
//     administrator_comments: "",
//   },
// ];
