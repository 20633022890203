import { React } from "react";
import LinkCard from "../../components/LinkCard.jsx";
import { BsBoxes } from "react-icons/bs";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import Paper from "@mui/material/Paper";
import ReportPageImage from "../../assets/Reports Page.jpg";
import ChatBot from "../../components/ChatBot.jsx";

export default function StandardReportSelect({ user, signOut }) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "60px" };

  return (
    <>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
        <Sidebar />
        <div className="pt-24 h-fit xl:mx-28 xl:mr-[25rem] 2xl:mr-[40rem] 2xl:ml-[15rem] ">
          <Paper className="" elevation={2}>
            <div className="text-center font-bold uppercase text-2xl py-2">
      Report
            </div>
            <div className="flex justify-center items-center">
              <div className="w-[584px] h-[300px] grid grid-cols-2 justify-center items-center">
                <Link to="/report/runreport">
                  <LinkCard
                    icon={<MdOutlineAutoFixHigh style={iconStyles} />}
                    linkText="Run Report"
                  />
                </Link>
                <Link to="/report/manage-reports">
                  <LinkCard
                    icon={<BsBoxes style={iconStyles} />}
                    linkText="Manage Reports"
                  />
                </Link>
              </div>

              <div className="w-[400px] rounded-lg flex justify-center items-center 2xl:w-[584px] py-5 px-5">
                <img src={ReportPageImage} alt="" />
              </div>
            </div>
          </Paper>
        </div>

        <div className="absolute right-0">
          <ChatBot />
        </div>
      </div>
    </>
  );
}
