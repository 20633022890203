import { BsBoxes } from "react-icons/bs";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { React, useState, useEffect, useRef } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function BenefitInventory({ user, signOut }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const benefitInventory = async () => {
    // setLoading(true);
    const allRows = await API.graphql({
      query: mutations.BenefitInventoryUserIdFilter,
      variables: { userid: user },
    });
    setRows(allRows.data.BenefitInventoryUserIdFilter);
    setLoading(false);
  };

  let navigate = useNavigate();

  useEffect(() => {
    benefitInventory();
  }, []);

  // console.log(rows);

  //

  const columns = [
    {
      field: "company_name",
      renderHeader: () => <strong>{"Company Name"}</strong>,
      width: 130,
    },
    {
      field: "industry_name",
      renderHeader: () => <strong>{"Industry Name"}</strong>,
      width: 150,
    },
    {
      field: "benefit_plan_display_name",
      renderHeader: () => <strong>{"Benefit Plan"}</strong>,
      width: 150,
    },
    {
      field: "benefit_category",
      renderHeader: () => <strong>{"Benefit Category"}</strong>,
      width: 130,
    },
    {
      field: "benefit_sub_category",
      renderHeader: () => <strong>{"Benefit Sub-Category"}</strong>,
      width: 150,
    },
    {
      field: "benefit_program_display_name",
      renderHeader: () => <strong>{"Benefit Plan"}</strong>,
      width: 150,
    },
    {
      field: "region",
      renderHeader: () => <strong>{"Region"}</strong>,
      width: 150,
    },
    {
      field: "sub_region",
      renderHeader: () => <strong>{"Sub-Region"}</strong>,
      width: 150,
    },
    {
      field: "country",
      renderHeader: () => <strong>{"Country"}</strong>,
      width: 150,
    },
    {
      field: "userid",
      renderHeader: () => <strong>{"UserId"}</strong>,
      width: 150,
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);

  const handleDeleteRow = async () => {
    console.log(selectedRows);
    let newRows = rows.filter(
      (row) => !selectedRows.includes(row.benefit_plan_id),
    );
    setRows(newRows);
    let delRows = rows.filter((row) => selectedRows.includes(row.benefit_plan_id));
    let delapirows = delRows.map(row=>({benefit_plan_id : row.benefit_plan_id}));
    // console.log(delapirows)
    const deleteOperation = await API.graphql({
      query: mutations.deleteBenefitDetails,
      variables: {
        delete_benefit_details: delapirows,
        userid: user,
      },
    });
  };

  const handleUpdateRow = async () => {
    // console.log(selectedRows[0]);
    // console.log(rows);
    let updateRow = rows.filter((row) => selectedRows.includes(row.benefit_plan_id));
    localStorage.setItem("updateBenefitPlanID", updateRow[0].benefit_plan_id);
    localStorage.setItem("updateBenefitID", updateRow[0].benefit_id);
    localStorage.setItem("updateBenefitCountry", updateRow[0].country);
    localStorage.setItem("updateBenefitCompany", updateRow[0].company_id);
    localStorage.setItem("updateBenefitPlanName", updateRow[0].benefit_plan_display_name);

    navigate("/design/updatebenefitinventory");
    
  };

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };

  const [action, setAction] = useState(false);

  let actionRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!actionRef.current.contains(e.target)) {
        setAction(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;} 
          .css-1si6n65{overflow-y: hidden!important;} 
          .css-frlfct{color:#9ca3af!important;} 
          .css-y599qu{color:#6b7280!important;}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex w-full">
          <Sidebar />
        <div className=" xl:ml-[3rem] 2xl:ml-[15rem] h-fit w-[855px] ">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/design" className="px-2">
              Design
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/design/benefitinventory">
              Benefit Inventory
            </Link>
          </div>

          {/* page Heading*/}
          <div>
            <section className="flex items-center">
              <BsBoxes style={iconStyles} />
              <p className="text-3xl mx-1">Benefit Inventory</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
          </div>
          {loading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress sx={{ color: "#DB2748" }} />{" "}
            </Backdrop>
          ) : (
            <>
              {" "}
              <div className="flex items-center space-x-3 justify-end w-[1050px] mr-4">
                <div className="flex justify-end mt-1">
                  <section ref={actionRef}>
                    <div className="flex w-full justify-center items-center">
                      <div className="flex justify-center items-center space-x-3">
                        <button
                          disabled={selectedRows.length == 1 ? false : true}
                          onClick={handleUpdateRow}
                          className={
                            selectedRows.length == 1
                              ? "bg-[#0000A3] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                                : "bg-gray-500 rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white disabled"
                          }
                        >
                        Update
                        </button>
                        <button
                          disabled={selectedRows.length > 0 ? false : true}
                          onClick={handleDeleteRow}
                          className={
                            selectedRows.length > 0
                              ? "bg-[#DB2748] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white"
                              : "bg-gray-500 rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-6  text-white disabled"
                          }
                        >
              Delete
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="h-[450px] w-[1050px] pt-8 mr-4">
                <DataGrid
                  style={{ overflowY: "scroll" }}
                  // rows={rows}
                  // rows={rows.map((item, index) => ({ id: index + 1, ...item }))}
                  // rows={rows.map((item, index) => ({ id: index + 1, ...item }))}
                  getRowId={(row) => row.benefit_plan_id}
                  rows={rows}
                  checkboxSelection
                  columns={columns}
                  onRowSelectionModelChange={(row) => setSelectedRows(row)}
                  hideFooter
                  hideFooterPagination
                  hideFooterSelectedRowCount
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}