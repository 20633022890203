import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { BsBoxes } from "react-icons/bs";
import { AiOutlineCaretDown } from "react-icons/ai";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Alerts from "../../components/Alerts.jsx";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";
import awsExports from "../../aws-exports.js";
API.configure(awsExports);

export default function ReconcileInstructionsPage({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [alert, setAlert] = useState("");

  const setReportProperties = async (
    company_id,
    company_name,
    company_status,
    report_request_id,
    recon_type,
    recon_type_display_name,
    input_file_id_1,
    input_file_id_1_name,
    input_file_id_1_template_id,
    input_file_id_1_template_name,
    input_file_id_1_template_type_id,
    input_file_id_1_template_type_name,
    input_file_id_2,
    input_file_id_2_name,
    input_file_id_2_template_id,
    input_file_id_2_template_name,
    input_file_id_2_template_type_id,
    input_file_id_2_template_type_name,
    created_dt,
    create_userid,
    publish_status,
    last_publish_dt,
    publish_userid,
    is_delete,
    deleted_dt,
    delete_userid
  ) => {
    const postSetReportProperties = await API.graphql({
      query: mutations.createreadReportReconDetails,
      variables: {
        company_id,
        company_name,
        company_status,
        report_request_id,
        recon_type,
        recon_type_display_name,
        input_file_id_1,
        input_file_id_1_name,
        input_file_id_1_template_id,
        input_file_id_1_template_name,
        input_file_id_1_template_type_id,
        input_file_id_1_template_type_name,
        input_file_id_2,
        input_file_id_2_name,
        input_file_id_2_template_id,
        input_file_id_2_template_name,
        input_file_id_2_template_type_id,
        input_file_id_2_template_type_name,
        created_dt,
        create_userid,
        publish_status,
        last_publish_dt,
        publish_userid,
        is_delete,
        deleted_dt,
        delete_userid,
      },
    }).then((data) => {
      console.log(data);
      localStorage.setItem(
        "reconRequestId",
        data["data"]["createreadReportReconDetails"]["report_request_id"]
      );
      navigate("/report/reconcile2");
    });
  };

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };
  const [reconType, setReconType] = useState({
    companyId: "",
    rtype: "",
    ttype1: "",
    ttype2: "",
    tname1: "",
    tname2: "",
    fname1: "",
    fname2: "",
    user: localStorage.getItem("loggedInUser"),
  });

  let listStyles = {
    position: "absolute",
    top: "0",
    right: "0",
    paddingTop: "4px",
    color: "#0000A3",
    fontSize: "25px",
  };

  const [tempReconList, setTempReconList] = useState([]);
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();

  const onChange = (e) => {
    setReconType({ ...reconType, [e.target.name]: e.target.value });
  };

  // const updateReconData = (reconData) => {
  //   reconType.companyId = reconData?.companyId;
  //   reconType.rtype = reconData?.rtype;
  //   reconType.ttype1 = reconData?.ttype1;
  //   reconType.ttype2 = reconData?.ttype2;
  //   reconType.tname1 = reconData?.tname1;
  //   reconType.tname2 = reconData?.tname2;
  //   reconType.fname1 = reconData?.fname1;
  //   reconType.fname2 = reconData?.fname2;
  // };

  const reportDropDownData = async () => {
    const readReportRawAttributes = await API.graphql({
      query: queries.readReportRawAttributes,
    });
    setTempReconList(readReportRawAttributes.data.readReportRawAttributes);
  };

  useEffect(() => {
    setloading(true);
    reportDropDownData();
    setWait(false);
    setloading(false);
  }, []);

  let reconCompanyId = [];
  for (var cid of tempReconList) {
    if (
      reconCompanyId.company_id !== cid.company_id &&
      cid.company_id !== null
    ) {
      let tempCid = {
        company_id: "",
        company_name: "",
      };
      tempCid.company_id = cid.company_id;
      tempCid.company_name = cid.company_name;
      reconCompanyId.push(tempCid);
    } else {
    }
  }
  console.log(reconCompanyId);

  const companyNamesList = [];
  const uniqueMapCid = new Map();
  for (const obj of reconCompanyId) {
    const objString = JSON.stringify(obj);
    if (!uniqueMapCid.has(objString)) {
      uniqueMapCid.set(objString, true);
      companyNamesList.push(obj);
    }
  }

  let reconTypeValues = [];
  for (var i of tempReconList) {
    if (
      reconTypeValues.recon_type !== i.recon_type &&
      i.company_id === reconType.companyId
    ) {
      let temp = {
        recon_type: "",
        recon_type_display_name: "",
      };
      temp.recon_type = i.recon_type;
      temp.recon_type_display_name = i.recon_type_display_name;
      reconTypeValues.push(temp);
    } else {
    }
  }

  const reconList = [];
  const uniqueMap = new Map();
  for (const obj of reconTypeValues) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap.has(objString)) {
      uniqueMap.set(objString, true);
      reconList.push(obj);
    }
  }

  let tempTemplateType1List = [];
  for (var t of tempReconList) {
    if (t.recon_type === reconType.rtype && t.template_type_id !== null) {
      let temp1 = {
        template_type_id: "",
        template_type_display_name: "",
      };
      temp1.template_type_id = t.template_type_id;
      temp1.template_type_display_name = t.template_type_display_name;
      tempTemplateType1List.push(temp1);
    } else {
    }
  }

  console.log(tempTemplateType1List);

  let templateType1List = [];
  const uniqueMap1 = new Map();
  for (const obj of tempTemplateType1List) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap1.has(objString)) {
      uniqueMap1.set(objString, true);
      templateType1List.push(obj);
    }
  }

  let tempTemplateType2List = [];
  for (var t1 of tempReconList) {
    if (t1.recon_type === reconType.rtype && t1.template_type_id !== null) {
      let temp2 = {
        template_type_id: "",
        template_type_display_name: "",
      };
      temp2.template_type_id = t1.template_type_id;
      temp2.template_type_display_name = t1.template_type_display_name;
      tempTemplateType2List.push(temp2);
    } else {
    }
  }

  let templateType2List = [];
  const uniqueMap2 = new Map();
  for (const obj of tempTemplateType2List) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap2.has(objString)) {
      uniqueMap2.set(objString, true);
      templateType2List.push(obj);
    }
  }

  let tempTemplateName1List = [];
  for (var t2 of tempReconList) {
    if (
      t2.recon_type === reconType.rtype &&
      t2.template_type_id === reconType.ttype1 &&
      t2.recon_type !== null &&
      t2.template_type_id !== null &&
      t2.template_id !== null
    ) {
      let temp3 = {
        template_id: "",
        template_display_name: "",
      };
      temp3.template_id = t2.template_id;
      temp3.template_display_name = t2.template_display_name;
      tempTemplateName1List.push(temp3);
    } else {
    }
  }

  let templateName1List = [];
  const uniqueMap3 = new Map();
  for (const obj of tempTemplateName1List) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap3.has(objString)) {
      uniqueMap3.set(objString, true);
      templateName1List.push(obj);
    }
  }

  let tempTemplateName2List = [];
  for (var t3 of tempReconList) {
    if (
      t3.recon_type === reconType.rtype &&
      t3.template_type_id === reconType.ttype2 &&
      t3.recon_type !== null &&
      t3.template_type_id !== null &&
      t3.template_id !== null
    ) {
      let temp4 = {
        template_id: "",
        template_display_name: "",
      };
      temp4.template_id = t3.template_id;
      temp4.template_display_name = t3.template_display_name;
      tempTemplateName2List.push(temp4);
    } else {
    }
  }

  let templateName2List = [];
  const uniqueMap4 = new Map();
  for (const obj of tempTemplateName2List) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap4.has(objString)) {
      uniqueMap4.set(objString, true);
      templateName2List.push(obj);
    }
  }

  let tempFileName1List = [];
  for (var t4 of tempReconList) {
    if (
      t4.recon_type === reconType.rtype &&
      t4.template_type_id === reconType.ttype1 &&
      t4.template_id === reconType.tname1 &&
      t4.file_id !== null
    ) {
      let temp5 = {
        file_id: "",
        filename: "",
      };
      temp5.file_id = t4.file_id;
      temp5.filename = t4.filename;
      tempFileName1List.push(temp5);
    } else {
    }
  }

  let fileName1List = [];
  const uniqueMap5 = new Map();
  for (const obj of tempFileName1List) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap5.has(objString)) {
      uniqueMap5.set(objString, true);
      fileName1List.push(obj);
    }
  }

  let tempFileName2List = [];
  for (var t5 of tempReconList) {
    if (
      t5.recon_type === reconType.rtype &&
      t5.template_type_id === reconType.ttype2 &&
      t5.template_id === reconType.tname2 &&
      t5.file_id !== null
    ) {
      let temp6 = {
        file_id: "",
        filename: "",
      };
      temp6.file_id = t5.file_id;
      temp6.filename = t5.filename;
      tempFileName2List.push(temp6);
    } else {
    }
  }

  let fileName2List = [];
  const uniqueMap6 = new Map();
  for (const obj of tempFileName2List) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap6.has(objString)) {
      uniqueMap6.set(objString, true);
      fileName2List.push(obj);
    }
  }

  useEffect(() => {
    localStorage.setItem("reconValues", JSON.stringify(reconType));
  });

  const getReconName = (value) => {
    for (var val of reconTypeValues) {
      if (val.recon_type === value) {
        return val.recon_type_display_name;
      }
    }
  };

  const getTemplateType = (value) => {
    for (var val of reconTypeValues) {
      if (val.template_type_id === value) {
        return val.template_type_display_name;
      }
    }
  };

  const getTemplateName = (value) => {
    for (var val of reconTypeValues) {
      if (val.template_id === value) {
        return val.template_display_name;
      }
    }
  };

  const getFileName = (value) => {
    for (var val of reconTypeValues) {
      if (val.file_id === value) {
        return val.filename;
      }
    }
  };

  const getCompanyName = (name) => {
    for (var val of reconTypeValues) {
      if (val.companyId === name) {
        return val.company_name;
      }
    }
  };
  const getCompanyStatus = (rtype, ttype, tname) => {
    for (var val of reconTypeValues) {
      if (
        val.recon_type === rtype &&
        val.template_type_id === ttype &&
        val.template_id === tname
      ) {
        return val.company_status;
      }
    }
  };
  const getCompanyId = (rtype, ttype, tname) => {
    for (var val of reconTypeValues) {
      if (
        val.recon_type === rtype &&
        val.template_type_id === ttype &&
        val.template_id === tname
      ) {
        return val.company_id;
      }
    }
  };

  const handleSave = (e) => {
    if (
      reconType.rtype.length < 1 ||
      reconType.ttype1.length < 1 ||
      reconType.ttype2.length < 1 ||
      reconType.tname1.length < 1 ||
      reconType.tname2.length < 1 ||
      reconType.ttype1 === reconType.ttype2 ||
      reconType.tname1 === reconType.tname2
    ) {
        e.preventDefault();
      setAlert("error");
    } else {
      e.preventDefault();
      setReportProperties(
        getCompanyId(reconType.rtype, reconType.ttype1, reconType.tname1),
        getCompanyName(reconType.companyId),
        getCompanyStatus(reconType.rtype, reconType.ttype1, reconType.tname1),
        "",
        reconType.rtype,
        getReconName(reconType.rtype),
        reconType.fname1,
        getFileName(reconType.fname1),
        reconType.tname1,
        getTemplateName(reconType.tname1),
        reconType.ttype1,
        getTemplateType(reconType.ttype1),
        reconType.fname2,
        getFileName(reconType.fname2),
        reconType.tname2,
        getTemplateName(reconType.tname2),
        reconType.ttype2,
        getTemplateType(reconType.ttype2),
        "",
        reconType.user,
        "unpublished",
        "",
        reconType.user,
        "",
        "",
        ""
      );
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    setReconType({
      companyId: "",
      rtype: "",
      ttype1: "",
      ttype2: "",
      tname1: "",
      tname2: "",
      fname1: "",
      fname2: "",
    });
  };

  return (
    <>
      <style>{`
          input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
          `}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />

        <div className="2xl:ml-[14.5rem] ml-12">
          {/* Navigation Link */}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/report" className="px-2">
              Report
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/report/reconcile1">
              Reconcile
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/report/reconcile1">
              Set Report Properties
            </Link>
          </div>

          {/* Page and Action Buttons */}
          <div className="flex justify-between mt-4 ">
            <section className="flex items-center">
              <BsBoxes style={iconStyles} />
              <p className="text-3xl mx-1">Reconcile</p>
              {/* <p className="text-[#0000A3] mt-2 text-sm">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts severity="success" info="Success, add you admin comments in the next page" />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Please fill all the Required Fields.!!!, And make you have selected 2 different file names" />
                ) : (
              ""
            )}
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex">
            <div className="ml-3">
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 1</p>
                <p className="font-bold text-md pb-1 ">Set Report Properties</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className="text-gray-600">Step 2</p>
                <p className="text-gray-600 text-md pb-1 ">
                  Add Admin Comments
                </p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
            </div>

            {!loading && (
              <div className="mt-4 w-[855px]">
                <form className="">
                  <div className="flex">
                    <div className="flex w-[315px] flex-col mx-14 my-3">
                      <div className="flex">
                        <label className="text-lg required" htmlFor="companyId">
                          Company Name
                        </label>
                        <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">
                          info
                        </p>
                      </div>
                      <div className="w-full relative inline-block">
                        <select
                          name="companyId"
                          id="companyId"
                          value={reconType.companyId}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          style={{ appearance: "none" }}
                          className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
                        >
                          <option value=""></option>
                          {companyNamesList.map((option, i) => (
                            <option
                              value={option.company_id}
                              className="bg-white p-4"
                              key={i}
                            >
                              {option.company_name
                                ? option.company_name
                                : "No name for the id in DB"}
                            </option>
                          ))}
                        </select>
                        <AiOutlineCaretDown style={listStyles} />
                      </div>
                    </div>
                    <div className="flex w-[315px] flex-col mx-14 my-3">
                      <div className="flex">
                        <label className="text-lg required" htmlFor="recon">
                          Recon Type
                        </label>
                        <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">
                          info
                        </p>
                      </div>
                      <div className="w-full relative inline-block">
                        <select
                          name="rtype"
                          id="rtype"
                          value={reconType.rtype}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          style={{ appearance: "none" }}
                          className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
                        >
                          <option value=""></option>
                          {reconList.map((option, i) => (
                            <option
                              value={option.recon_type}
                              className="bg-white p-4"
                              key={i}
                            >
                              {option.recon_type_display_name}
                            </option>
                          ))}
                        </select>
                        <AiOutlineCaretDown style={listStyles} />
                      </div>
                    </div>
                  </div>

                  <hr className="h-[1px] bg-[#7A7A7A] ml-14 my-10" />
                  <div className="grid grid-cols-2">
                    <div className="flex w-[315px] flex-col mx-14 my-3">
                      <div className="flex">
                        <label className="text-lg required" htmlFor="ttype1">
                          Template Type 1
                        </label>
                        <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">
                          info
                        </p>
                      </div>
                      <div className="w-full relative inline-block">
                        <select
                          name="ttype1"
                          id="ttype1"
                          value={reconType.ttype1}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          style={{ appearance: "none" }}
                          className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
                        >
                          <option value=""></option>
                          {templateType1List.map((option, i) => (
                            <option
                              value={option.template_type_id}
                              className="bg-white p-4"
                              key={i}
                            >
                              {option.template_type_display_name}
                            </option>
                          ))}
                        </select>
                        <AiOutlineCaretDown style={listStyles} />
                      </div>
                    </div>

                    <div className="flex w-[315px] flex-col mx-14 my-3">
                      <div className="flex">
                        <label className="text-lg required" htmlFor="ttype2">
                          Template Type 2
                        </label>
                        <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">
                          info
                        </p>
                      </div>
                      <div className="w-full relative inline-block">
                        <select
                          name="ttype2"
                          id="ttype2"
                          value={reconType.ttype2}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          style={{ appearance: "none" }}
                          className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
                        >
                          <option value=""></option>
                          {templateType2List.map((option, i) => (
                            <option
                              value={option.template_type_id}
                              className="bg-white p-4"
                              key={i}
                            >
                              {option.template_type_display_name}
                            </option>
                          ))}
                        </select>
                        <AiOutlineCaretDown style={listStyles} />
                      </div>
                    </div>

                    <div className="flex w-[315px] flex-col mx-14 my-3">
                      <div className="flex">
                        <label className="text-lg required" htmlFor="tname1">
                          Template Name 1
                        </label>
                        <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">
                          info
                        </p>
                      </div>
                      <div className="w-full relative inline-block">
                        <select
                          name="tname1"
                          id="tname1"
                          value={reconType.tname1}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          style={{ appearance: "none" }}
                          className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
                        >
                          <option value=""></option>
                          {templateName1List.map((option, i) => (
                            <option
                              value={option.template_id}
                              className="bg-white p-4"
                              key={i}
                            >
                              {option.template_display_name}
                            </option>
                          ))}
                        </select>
                        <AiOutlineCaretDown style={listStyles} />
                      </div>
                    </div>

                    <div className="flex w-[315px] flex-col mx-14 my-3">
                      <div className="flex">
                        <label className="text-lg required" htmlFor="tname2">
                          Template Name 2
                        </label>
                        <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">
                          info
                        </p>
                      </div>
                      <div className="w-full relative inline-block">
                        <select
                          name="tname2"
                          id="tname2"
                          value={reconType.tname2}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          style={{ appearance: "none" }}
                          className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
                        >
                          <option value=""></option>
                          {templateName2List.map((option, i) => (
                            <option
                              value={option.template_id}
                              className="bg-white p-4"
                              key={i}
                            >
                              {option.template_display_name}
                            </option>
                          ))}
                        </select>
                        <AiOutlineCaretDown style={listStyles} />
                      </div>
                    </div>

                    <div className="flex w-[315px] flex-col mx-14 my-3">
                      <div className="flex">
                        <label className="text-lg required" htmlFor="fname1">
                          Filename 1
                        </label>
                        <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">
                          info
                        </p>
                      </div>
                      <div className="w-full relative inline-block">
                        <select
                          name="fname1"
                          id="fname1"
                          value={reconType.fname1}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          style={{ appearance: "none" }}
                          className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
                        >
                          <option value=""></option>
                          {fileName1List.map((option, i) => (
                            <option
                              value={option.file_id}
                              className="bg-white p-4"
                              key={i}
                            >
                              {option.filename}
                            </option>
                          ))}
                        </select>
                        <AiOutlineCaretDown style={listStyles} />
                      </div>
                    </div>

                    <div className="flex w-[315px] flex-col mx-14 my-3">
                      <div className="flex">
                        <label className="text-lg required" htmlFor="fname2">
                          Filename 2
                        </label>
                        <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">
                          info
                        </p>
                      </div>
                      <div className="w-full relative inline-block">
                        <select
                          name="fname2"
                          id="fname2"
                          value={reconType.fname2}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          style={{ appearance: "none" }}
                          className="border bg-white border-gray-300 px-2 h-[30px] py-[2px] z-20 w-full"
                        >
                          <option value=""></option>
                          {fileName2List.map((option, i) => (
                            <option
                              value={option.file_id}
                              className="bg-white p-4"
                              key={i}
                            >
                              {option.filename}
                            </option>
                          ))}
                        </select>
                        <AiOutlineCaretDown style={listStyles} />
                      </div>
                    </div>
                    <div className="h-1" />
                  </div>

                  <div className=" pt-8 w-full flex justify-end items-center">
                    <button
                      onClick={(e) => {
                        handleClear(e);
                      }}
                      className="ml-10  text-gray-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-10 bg-[#0000A3] px-6 py-[6px]  text-white"
                      onClick={(e) => {
                        handleSave(e);
                      }}
                    >
                      Run
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}



          // <div className="pt-10">
          //   {" "}
          //   {JSON.stringify(reconType, null, "    ")}
          // </div>

// const reportDropDownData = async () => {
//     setloading(true)
//   const readReportRawAttributes = await API.graphql({
//     query: queries.readReportRawAttributes,
//   });
//   let reconTypeValues = [];
//   for  (let i in readReportRawAttributes.data.readReportRawAttributes) {
//     let item =
//       readReportRawAttributes.data.readReportRawAttributes[i]
//         .recon_type_display_name;
//     if (reconTypeValues[i] === item) {
//       return;
//     } else {
//       reconTypeValues.push(item);
//     }
//   }
//   setReconList([...new Set(reconTypeValues)]);
// };
//
// useEffect(() => {
//   setloading(true);
//   reportDropDownData();
//   setloading(false);
//   return () => {};
// }, []);

//
//       let test = [];
// useEffect(() => {
//   const reportDropDownData = async () => {
//     const readReportRawAttributes = await API.graphql({
//       query: queries.readReportRawAttributes,
//     });
//       setTempReconList(readReportRawAttributes)
//
//       // console.log(readReportRawAttributes.data.readReportRawAttributes)
//       // for(let item of readReportRawAttributes.data.readReportRawAttributes){
//       //     if(test.includes(item.recon_type_display_name )){
//       //        return
//       //     }else{
//       //         test.push(item.recon_type_display_name )
//       //     }
//       // }
//       // console.log(test)
//       // setTempReconList(readReportRawAttributes)
//       // console.log(tempReconList)
//     // console.log(readReportRawAttributes.data.readReportRawAttributes);
//       // setTempReconList(readReportRawAttributes)
//       // console.log(tempReconList)
//       // // let reconTypeAttributes = tempReconList.filter((item, id) => {tempReconList.indexOf(item) = id})
//       // // const reconTypeAttributes = tempReconList.filter((val, id, array) => array.indexOf(val) === id)
//       // const reconTypeAttributes = Array.from(new Set(tempReconList))
//       // console.log(reconTypeAttributes)
//       // let reconTypeAttributes = [];
//       // for(let attribute in tempReconList){
//       //     console.log(attribute)
//       // }
//       // setReconList([...new Set(readReportRawAttributes.data.readReportRawAttributes)])
//
//     // const updatedStateList =
//     //   await readCompanyAttributes.data.readCompanyAttributes.filter(
//     //     (item) => {
//     //       return item.attribute_key_display_name === "State";
//     //     }
//     //   );
//     //
//   };
//
//   reportDropDownData();
//
//   return () => {};
// }, []);
//
//     console.log(tempReconList)
// console.log(reconList)
// const typeList = ["type", "type", "type", "type", "type"];
// const nameList = ["name", "name", "name", "name", "name"];
// const fileList = ["name", "name", "name", "name", "name"];
//
// for await (let i of readReportRawAttributes.data.readReportRawAttributes) {
//   let item  =
//     readReportRawAttributes.data.readReportRawAttributes[i]
//       .recon_type_display_name;
//   if (reconTypeValues[i] === item) {
//     return;
//   } else {
//     reconTypeValues.push(item);
//   }
// }
// setReconList([...new Set(reconTypeValues)]);

// setReconList(readReportRawAttributes.data.readReportRawAttributes);
//
//

//       setReportProperties(
//         "",
//         "",
//         "",
//         "",
//         reconType.rtype,
//         getReconName(reconType.rtype),
//         reconType.fname1,
//         getFileName(reconType.fname1),
//         reconType.tname1,
//         getTemplateName(reconType.tname1),
//         reconType.ttype1,
//         getTemplateType(reconType.ttype1),
//         reconType.fname2,
//         getFileName(reconType.fname2),
//         reconType.tname2,
//         getTemplateName(reconType.tname2),
//         reconType.ttype2,
//         getTemplateType(reconType.ttype2),
//         "",
//         reconType.user,
//         "unpublished",
//         "",
//         reconType.user,
//         "",
//         "",
//         ""
//       );
// {
//
//         company_id,
//         company_name,
//         company_status,
//         report_request_id,
//         recon_type,
//         recon_type_display_name,
//         input_file_id_1,
//         input_file_id_1_name,
//         input_file_id_1_template_id,
//         input_file_id_1_template_name,
//         input_file_id_1_template_type_id,
//         input_file_id_1_template_type_name,
//         input_file_id_2,
//         input_file_id_2_name,
//         input_file_id_2_template_id,
//         input_file_id_2_template_name,
//         input_file_id_2_template_type_id,
//         input_file_id_2_template_type_name,
//         created_dt,
//         create_userid,
//         publish_status,
//         last_publish_dt,
//         publish_userid,
//         is_delete,
//         deleted_dt,
//         delete_userid,
// }
