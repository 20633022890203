import { React, useEffect, useState } from "react";
import ColMapNested from "../../components/ColMapNested";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Alerts from "../../components/Alerts.jsx";
import { Link, useNavigate } from "react-router-dom";
import { BsBoxArrowInRight } from "react-icons/bs";
import { IContext } from "../../context/IntegrateContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";

export default function CreateTemplateS4({ user, signOut }) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };
  const [wait, setWait] = useState(true);
  const [masterJson, setMasterJson] = useState([]);
  const [alert, setAlert] = useState("");
  const [createTemplateS1Values, setCreateTemplateS1Values] = useState([]);
  // const { getS4ColumnData, getS4ItemData } = IContext();
  const {testData} = IContext();

  // console.log("dropdown data", getS4ItemData);
  // console.log("col data", getS4ColumnData);
  let testData1 = [
    {
      key: "target_gender",
      keyDisplayName: "Gender",
      source_columns: [
        {
          column: "MBR_GNDR_CD",
          source_values: ["M  ", "F  ", "U  "],
          option: [
            {
              option: "F",
              optionDisplayName: "Female",
            },
            {
              option: "M",
              optionDisplayName: "Male",
            },
            {
              option: "U",
              optionDisplayName: "Undisclosed",
            },
          ],
        },
      ],
    },

    {
      key: "target_gender",
      keyDisplayName: "Gender",
      source_columns: [
        {
          column: "MBR_GNDR_CD",
          source_values: ["M  ", "F  ", "U  "],
          option: [
            {
              option: "F",
              optionDisplayName: "Female",
            },
            {
              option: "M",
              optionDisplayName: "Male",
            },
            {
              option: "U",
              optionDisplayName: "Undisclosed",
            },
          ],
        },
      ],
    },
    {
      key: "target_gender",
      keyDisplayName: "Gender",
      source_columns: [
        {
          column: "MBR_GNDR_CD",
          source_values: ["M  ", "F  ", "U  "],
          option: [
            {
              option: "F",
              optionDisplayName: "Female",
            },
            {
              option: "M",
              optionDisplayName: "Male",
            },
            {
              option: "U",
              optionDisplayName: "Undisclosed",
            },
          ],
        },
      ],
    },
  ];

  // console.log("hello", testData[0].source_columns[0].source_values);
  // console.log(testData[0].key);

  setTimeout(() => {
    setWait(false);
  }, 1500);

  let displayName = ["datta"];
  // for (let name of getS4ColumnData) {
  //   let result = name.replace("target_", "");
  //   let final = result.replaceAll("_", " ");
  //   displayName.push(final);
  // }

  useEffect(() => {
    const getCreateTemplate1Selection = async () => {
      const createTemplate3Selection = await API.graphql({
        query: queries.readIntegrateAttributes,
      });
      setMasterJson(createTemplate3Selection.data.readIntegrateAttributes);
      // setWait(false);
    };
    getCreateTemplate1Selection();

    const unParsedFormDatas = localStorage.getItem("formTemplateType");

    // localStorage.setItem("formTemplateType", JSON.stringify(templateType));
    // localStorage.setItem("formTemplateName", JSON.stringify(templateName));
    // localStorage.setItem("formCompanyName", JSON.stringify(companyName));
    // localStorage.setItem("formCountry", JSON.stringify(country));
    const formTemplateType = JSON.parse(unParsedFormDatas);
    // console.log("formData",formTemplateType);
    setCreateTemplateS1Values(formTemplateType);
  }, []);

  console.log("mj",masterJson);
  let temp = [];
  let dummyColNames = [];
  // for (let c of getS4ColumnData) {
  //   let tempColName = [];
  //   // console.log(masterJson)
  //   for (let v of masterJson) {
  //     if (
  //       v.component_type === "key_value_map" &&
  //       v.template_type_id === createTemplateS1Values &&
  //       v.attribute_key_name === c
  //     ) {
  //       let dict = {
  //         option: "",
  //         optionDisplayName: "",
  //       };
  //       dict.optionDisplayName = v.attribute_list_value_display_name;
  //       dict.option = v.attribute_list_valid_value;
  //       dummyColNames.push(dict);
  //       tempColName.push(dict.option);
  //     } else {
  //     }
  //   }
  //
  //   // const newCol = tempColName.filter(
  //   //   (col, i) => tempColName.indexOf(col) === i,
  //   // );
  //
  //   // const newCol = dummyColNames.filter(
  //   //   (col, i) => dummyColNames.indexOf(col) === i,
  //   // );
  //   // console.log("newcol", newCol);
  //   // temp.push(newCol);
  //   // console.log("temp", temp);
  // }
  // console.log(dummyColNames);
  // console.log("temp", temp);
  //
  // console.log("op", getS4ItemData);

  let navigate = useNavigate();

  const pracArray = [];
  // console.log("data", getS4ItemData);
  // console.log("opt", temp);

  // for (let i = 0; i < getS4ItemData.length; i++) {
  //   let tempPracArray = [];
  //   for (let k = 0; k < getS4ItemData[i].length; k++) {
  //     let tempDict = {};
  //     tempDict.column = getS4ItemData[i][k];
  //     tempDict.options = temp[i];
  //     tempPracArray.push(tempDict);
  //   }
  //   pracArray.push(tempPracArray);
  // }
  // console.log("pracarray", pracArray);

  for (let i = 0; i < testData.length; i++) {
    let tempPracArray = [];
    for (let tc of testData[i].source_columns[0].source_values) {
      let tempDict = {};
      tempDict.column = tc;
      tempDict.options = testData[i].source_columns[0].option;
      tempPracArray.push(tempDict);
    }
    pracArray.push(tempPracArray);
  }
  // console.log(pracArray);
  // for(let tempC of test)

  // let testLeft = testData[0].source_columns[0].option;

  // console.log("tl", testLeft);

  let tempColumnData = [];
  for (let i = 0; i < pracArray.length; i++) {
    let start = 0;
    let nesting = {};
    for (let j = 0; j < pracArray[i].length; j++) {
      nesting[pracArray[i][j].column] = start;
      start++;
    }
    tempColumnData.push(nesting);
  }
  // console.log("tcd", tempColumnData);

  const getMappedOption = (batchNumber, optionNumber, optionValue) => {
    // console.log(batchNumber, optionNumber, optionValue);
    for (let i = 0; i < tempColumnData.length; i++) {
      let start = 0;
      for (let key in tempColumnData[i]) {
        if (
          batchNumber === i &&
          (optionNumber === tempColumnData[i][key] || optionNumber === start)
        ) {
          tempColumnData[i][key] = optionValue;
        }
        start++;
      }
    }
    console.log(tempColumnData);
  };
  console.log("before Final Json", tempColumnData);

  const keyValueMapping = async () => {
    let finalJson = tempColumnData;
    // let start = 0;
    // for(let i = 0; i < testData.length; i++){
    //
    // }
    // for (let key of getS4ColumnData) {
    //   finalJson[key] = tempColumnData[start];
    //   start++;
    // }

    console.log("fj", finalJson);

    const neededJson = {
      // localStorage.setItem("formTemplateType", JSON.stringify(templateType));
      // localStorage.setItem("formTemplateName", JSON.stringify(templateName));
      // localStorage.setItem("formCompanyName", JSON.stringify(companyName));
      // localStorage.setItem("formCountry", JSON.stringify(country));
      //
      // const benefitCounty = JSON.parse(localStorage.getItem("contryFromBenefitPage1"),);
      template_id: JSON.parse(
        localStorage.getItem("frontend_generated_template_id"),
      ),
      template_type_id: JSON.parse(localStorage.getItem("formTemplateType")),
      company_id: JSON.parse(localStorage.getItem("formCompanyName")),
      country_code: JSON.parse(localStorage.getItem("formCountry")),
      userid: user,
    };
    console.log("nj", neededJson);
    // attribute_source_value: String
    // 	attribute_target_value: String
    // 	company_id: String
    // 	country_code: String
    // 	source_column: String
    // 	target_column: String
    // 	template_id: String
    // 	template_type_id: String
    // 	userid: Stringi

    let resultJSON = [];

    for (let key in finalJson) {
      for (let subkey in finalJson[key]) {
        let tempJSON = { ...neededJson };
        tempJSON.target_column = key;
        tempJSON.source_column = key.replace("target", "source");
        tempJSON.attribute_source_value = subkey;
        tempJSON.attribute_target_value = finalJson[key][subkey];
        resultJSON.push(tempJSON);
      }
    }

    console.log("rj", resultJSON);

    const uploadKeyValueMappings = await API.graphql({
      query: mutations.createIntegrateTemplateKeyValueMap,
      variables: {
        key_value_map_data: resultJSON,
      },
    }).then((data) => {
      console.log(data);
      if (data.createIntegrateTemplateKeyValueMap === "Success") {
        navigate("/integrate/manage-template");
      }
    });
  };
  console.log(pracArray);

  const handleSave = async (e) => {
    e.preventDefault();
    setAlert("success");
    await keyValueMapping();
    // navigate("/integrate/manage-template");
  };

  const handleClear = (e) => {
    e.preventDefault();
    navigate("/integrate/createtemplates1");
  };

  // console.log(pracArray);

  return (
    <>
      {" "}
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
        <Sidebar />
        <div className="2xl:ml-[14.5rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates1">
              Create Template
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates4">
              Map keys to values
            </Link>
          </div>

          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <BsBoxArrowInRight style={iconStyles} />
              <p className="text-3xl mx-1">Create Template</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            <section className="">
              {alert === "success" ? (
                <Alerts
                  severity="success"
                  info="Template Creation Successful"
                />
              ) : alert === "error" ? (
                <Alerts
                  severity="error"
                  info="Please fill all the Required Fields.!!!"
                />
              ) : (
                ""
              )}
            </section>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex">
            {/*steps*/}
            <div className="ml-3">
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 1</p>
                <p className="text-md pb-1">Set Template Properties</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 2</p>
                <p className="text-md pb-1 ">Capture addtional fields</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 3</p>
                <p className="text-md pb-1">Map columns</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 4</p>
                <p className="font-bold text-md pb-1 ">Map keys to values</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
            </div>

            {/* form*/}
            <div className="mt-4  ml-[7.5rem] max-h-[500px] overflow-y-scroll">
              {pracArray.map((col, key) => (
                <div className="mb-12  space-y-4" key={key}>
                  <div key={key} className="capitalize text-center mb-2">
                    {displayName[key]}
                  </div>
                  <div>
                    {col.map((c, index) => (
                      <ColMapNested
                        column={c.column}
                        key={index}
                        parentItno={key}
                        itno={index}
                        items={c.options}
                        getMappedOption={getMappedOption}
                      />
                    ))}
                  </div>
                </div>
              ))}
              <div className="pt-16 w-full flex justify-end items-center">
                <button
                  onClick={(e) => {
                    handleClear(e);
                  }}
                  className="ml-10 text-gray-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ml-10 bg-[#AEB3B7] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                  onClick={() => {
                    navigate("/integrate/createtemplates3");
                  }}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="ml-5 bg-[#0000A3] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                  onClick={(e) => {
                    handleSave(e);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /*
                *
                *
                *
                *
                      hello
                      {testData.map((col, key) => (
                        <div className="mb-12  space-y-4">
                          <div key={key} className="capitalize text-center mb-2">
                            {testData[key].keyDisplayName}
                          </div>
        
        
            {
              key: "target_gender",
              keyDisplayName: "Gender",
              source_columns: [
                {
                  column: "MBR_GNDR_CD",
                  source_values: ["M  ", "F  ", "U  "],
                  option: [
                    {
                      option: "F",
                      optionDisplayName: "Female",
                    },
                    {
                      option: "M",
                      optionDisplayName: "Male",
                    },
                    {
                      option: "U",
                      optionDisplayName: "Undisclosed",
                    },
                  ],
                },
              ],
            },
                          <div>
                            {col.source_columns.map((c, index) => (
                              <div>
                                {c.source_values.map((left, i) => (
                                  <ColMapNested
                                    column={left}
                                    key={index}
                                    parentItno={key}
                                    itno={index}
                                    items={c.option}
                                    getMappedOption={getMappedOption}
                                  />
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
        
                                                {pracArray.map((col, key) => (
                                                  <div className="mb-12  space-y-4">
                                                    <div key={key} className="capitalize text-center mb-2">
                                                      {displayName[key]}
                                                    </div>
                                                    <div>
                                                      {col.map((c, index) => (
                                                        <ColMapNested
                                                          column={c.column}
                                                          key={index}
                                                          parentItno={key}
                                                          itno={index}
                                                          items={c.options}
                                                          getMappedOption={getMappedOption}
                                                        />
                                                      ))}
                                                    </div>
                                                  </div>
                                                ))}
                                                <div className="pt-16 w-full flex justify-end items-center">
                                                  <button
                                                    // onClick={(e) => {
                                                    //   handleClear(e);
                                                    // }}
                                                    className="ml-10 text-gray-500"
                                                  >
                                                    Cancel
                                                  </button>
                                                  <button
                                                    type="submit"
                                                    className="ml-10 bg-[#AEB3B7] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                                                    onClick={() => {
                                                      navigate("/integrate/createtemplates3");
                                                    }}
                                                  >
                                                    Back
                                                  </button>
                                                  <button
                                                    type="submit"
                                                    className="ml-5 bg-[#0000A3] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                                                    onClick={(e) => {
                                                      handleSave(e);
                                                    }}
                                                  >
                                                    Submit
                                                  </button>
                                                </div>
                                             */
}

// {temp.map((col) =>
//   col.map((c, key) => (
//     <ColMap
//       column={c}
//       key={key}
//       itno={key}
//       items={getS4ItemData}
//       getMappedOption={getMappedOption}
//     />
//   ))
// )}

{
  /*temp.forEach((col) =>  col.map(c) => (
                                                                                                  <p>{c}</p>
                                                                                                ) )*/
}
{
  /*getS4ColumnData.map((column, key) => (
                                                                                                  <p>{c}</p>
                                                                                                              <ColMap
                                                                                                                column={column}
                                                                                                                key={key}
                                                                                                                itno={key}
                                                                                                                items={getS4ItemData}
                                                                                                                getMappedOption={getMappedOption}
                                                                                                              />
                                                                                                            ))*/
}

// <div>
//   {recipes.map((recipe) => {
//     return (
//       <div key={recipe.id}>
//         <h1>{recipe.title}</h1>
//         <img src={recipe.image} alt="recipe image" />
//         {recipe.dishTypes.map((type, index) => {
//           return <span key={index}>{type}</span>;
//         })}
//       </div>
//     );
//   })}
// </div>
//
// console.log(testTemp);

// tempColumnData.push(tempMappedColData);
// for (let colName of getS4ColumnData) {
//   tempColumnData[colName] = start;
//   start++;
// }
//

// for (let opts of tempopts) {
//   for (let cols of tempcols) {
//     for (let co of cols) {
//       let tempCols = {};
//       tempCols.column = co;
//       tempCols.options = opts;
//         pracArray.push(tempCols)
//       // console.log(co)
//       // console.log(opts)
//     }
//   }
// }

// for (let cols of tempcols) {
//   for (let co of cols) {
//     for (let opts of tempopts) {
//       let tempCols = {};
//       tempCols.column = co;
//       tempCols.options = opts;
//         pracArray.push(tempCols)
//       // console.log(co)
//       // console.log(opts)
//     }
//   }
// }
//
//

// console.log(tempcols[i])

// for (let i = 0; i < tempcols.length; i++) {
//   const columns = tempcols[i];
//   const options = tempopts[i];
//
//   for (let j = 0; j < columns.length; j++) {
//     const column = columns[j];
//     const option = options[j] || null;
//
//     result.push({
//       column: column,
//       options: [option],
//     });
//   }
// }

// console.log(result);
//
// const tempcols = [
//   ["Check Me"],
//   ["Monthly", "Weekly", "Semi-Monthly", "Bi-Weekly"],
//   [
//     "Other",
//     "Domestic Partner",
//     "Child",
//     "Parent-In-Law",
//     "Spouse",
//     "Parent",
//     "Employee",
//   ],
// ];
//
// const tempopts = [["1"], ["M"], ["Employee", "Spouse", "Child"]];
//
//
// console.log("hello", pracArray[i][j])
//
// console.log("array",pracArray[j])
// for (let k = 0; k < pracArray[i].length; k++) {
//   // console.log(pracArray[j][k]);
// }
// console.log(pracArray[j]);
// tempMappedColData.push(pracArray[j]);
// testTemp.push(start);

// tempColumnData[i].findIndex(col =>  ===)
// for(let j = 0; j < Object.keys(tempColumnData[i]).length; j++){
//       // console.log(i,j);
//     if(batchNumber === i && optionNumber === j){
//         tempColumnData[batchNumber][optionNumber] = optionValue
//     }
// }
//
//
// let start = 0;
//   console.log("batchNumber",batchNumber)
//   console.log("optionNumber",optionNumber)
//   console.log("optionValue",optionValue)
// // for (let key in tempColumnData) {
// //   if (optionNumber === tempColumnData[key] || optionNumber === start) {
// //     tempColumnData[key] = optionValue;
// //   }
// //   start++;
// // }
//
// console.log(tempColumnData);
//
// let test = [
//   {
//     "Check Me": 0,
//   },
//   {
//     Monthly: 0,
//     Weekly: 1,
//     "Semi-Monthly": 2,
//     "Bi-Weekly": 3,
//   },
//   {
//     Other: 0,
//     "Domestic Partner": 1,
//     Child: 2,
//     "Parent-In-Law": 3,
//     Spouse: 4,
//     Parent: 5,
//     Employee: 6,
//   },
// ];

// for (let i = 0; i < tempColumnData.length; i++) {
//   for(let j = 0; j < Object.keys(tempColumnData[i]).length; j++){
//         // console.log(i,j);
//
//   }
// }
//

// for (let i = 0; i < tempColumnData.length; i++) {
//     for(let key in tempColumnData[i] ){
//         console.log(key)
//     }
//     // console.log(tempColumnData[i])
//   // for(let j = 0; j < Object.keys(tempColumnData[i]).length; j++){
//   //       // console.log(i,j);
//   //
//   // }
// }
