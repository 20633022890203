import { React, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IContext } from "../../context/IntegrateContext.jsx";
import { MdCloudUpload, MdOutlineCancel } from "react-icons/md";
import { BsBoxArrowInRight } from "react-icons/bs";
import { AiOutlineCaretDown } from "react-icons/ai";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations.js";
import { Storage } from "aws-amplify";
import { BsEject } from "react-icons/bs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MdFilterListAlt } from "react-icons/md";
import Alerts from "../../components/Alerts.jsx";
import Paper from "@mui/material/Paper";
import { CContext } from "../../context/CompanyContext.jsx";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill } from "react-icons/pi";

export default function CreateTemplateS2({ user, signOut }) {
  const [wait, setWait] = useState(true);
  const [masterJson, setMasterJson] = useState([]);
  const [benefitProgramAndPlans, setBenefitProgramAndPlans] = useState([]);
  const [onlyBenefitPlansList, setOnlyBenefitPlansList] = useState([]);
  const [createTemplateS1Values, setCreateTemplateS1Values] = useState([]);
  const [fileExtension, setfileExtension] = useState("");
  const [startRow, setstartRow] = useState("");
  const [delimiter, setdelimiter] = useState("");
  const [sheetName, setsheetName] = useState("");
  const [dateFormat, setdateFormat] = useState("");
  const [benefitProgram, setbenefitProgram] = useState([]);
  const [benefitPlan, setbenefitPlan] = useState([]);
  const [containsEmployee, setcontainsEmployee] = useState("");
  const [containsDependent, setcontainsDependent] = useState("");
  const [employeePrimaryKey, setemployeePrimaryKey] = useState("");
  const [dependentPrimarykey, setdependentPrimarykey] = useState("");
  const { handleFileRead, s3ColumnData } = IContext();

  let fileExtensionList = [];
  let dateformatList = [];
  let delimiterList = [];
  let dependent = [];
  let employee = [];
  const [benefitPrograms, setBenefitPrograms] = useState([]);
  const [benefitPlans, setBenefitPlans] = useState([]);
  let benefitProgramList = [];
  let employeePrimaryKeyList = [];
  let dependentPrimarykeyList = [];
  let benefitPlanList = [];
  let checkBenefitPlanList = [];
  let checkBenefitProgramList = [];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  let fileStyles = {
    color: "#0000A3",
    fontSize: "50px",
    margin: "auto",
  };

  const ddRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(newFile);
    if (newFile) {
      const updatedList = [newFile];
      setFileList(updatedList);
      // console.log(updatedList);
    }
  };

  // const updateBenefitData = async (benefitData) => {
  //   benefit.benefitProgram = benefitData?.benefitProgram;
  //   benefit.benefitPlans = benefitData?.benefitPlans;
  //   benefit.contains_employees_bool = benefitData?.contains_employees_bool;
  //   benefit.census_source_employee_primary_key =
  //     benefitData?.census_source_employee_primary_key;
  //   benefit.contains_dependents_bool = benefitData?.contains_dependents_bool;
  //   benefit.census_source_dependent_primary_key =
  //     benefitData?.census_source_dependent_primary_key;
  //   benefit.source_file_extension = benefitData?.source_file_extension;
  //   benefit.column_start_row = benefitData?.column_start_row;
  //   benefit.source_file_delimiter = benefitData?.source_file_delimiter;
  //   benefit.sheet_name = benefitData?.sheet_name;
  //   benefit.source_file_date_format = benefitData?.source_file_date_format;
  // };

  const handleFileExtensionChange = (event) => {
    setfileExtension(event.target.value);
  };
  const handleStartRowChange = (event) => {
    setstartRow(event.target.value);
  };
  const handleDelimiterChange = (event) => {
    setdelimiter(event.target.value);
  };
  const handleSheetNameChange = (event) => {
    setsheetName(event.target.value);
  };
  const handleDateFormatChange = (event) => {
    setdateFormat(event.target.value);
  };
  const handleBenefitProgramChange = (event) => {
    const {
      target: { value },
    } = event;

    setbenefitProgram(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleBenefitPlanChange = (event) => {
    const {
      target: { value },
    } = event;

    setbenefitPlan(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const handleContainsEmployeeChange = (event) => {
    setcontainsEmployee(event.target.value);
  };
  const handleContainsDependentChange = (event) => {
    setcontainsDependent(event.target.value);
  };
  const handleEmployeePrimaryKeyChange = (event) => {
    setemployeePrimaryKey(event.target.value);
  };
  const handleDependentPrimaryKeyChange = (event) => {
    setdependentPrimarykey(event.target.value);
  };

  useEffect(() => {
    const getCreateTemplate1Selection = async () => {
      const createTemplate2Selection = await API.graphql({
        query: queries.readIntegrateAttributes,
      });
      setMasterJson(createTemplate2Selection.data.readIntegrateAttributes);
      setWait(false);
    };

    const getBenefitPlansAndPrograms = async () => {
      const createTemplateS2BenefitValues = await API.graphql({
        query: queries.readBenefitDetails,
      });
      setBenefitProgramAndPlans(
        createTemplateS2BenefitValues.data.readBenefitDetails,
      );
    };

    const getBenefitPlans = async () => {
      const createTemplateS2BenefitValues = await API.graphql({
        query: mutations.IntegrateBenefitPlanIdFilter,
        variables: {
          company_id: JSON.parse(localStorage.getItem("formCompanyName")),
          country: JSON.parse(localStorage.getItem("formCountry")),
          template_type_id: JSON.parse(
            localStorage.getItem("formTemplateType"),
          ),
        },
      });
      setOnlyBenefitPlansList(
        createTemplateS2BenefitValues.data.IntegrateBenefitPlanIdFilter,
      );
    };

    // const getCreateTemplateCompanyNames = async () => {
    //   const createTemplateCompanyNames = await API.graphql({
    //   query: mutations.IntegrateBenefitPlanIdFilter,
    //   variables: {
    //       userid:user
    //   },
    //   });
    //   setTempCompanyNames(createTemplateCompanyNames.data.CompanyListUseridFilter);
    //   setWait(false);
    // };

    getCreateTemplate1Selection();
    // getBenefitPlansAndPrograms();
    getBenefitPlans();

    const unParsedBenefitData = localStorage.getItem("benefitValues");
    const benefitData = JSON.parse(unParsedBenefitData);
    // updateBenefitData(benefitData);
    const unParsedFormDatas = localStorage.getItem("formValues");
    const formData = JSON.parse(unParsedFormDatas);
    setCreateTemplateS1Values(formData);
    console.log(
      JSON.parse(localStorage.getItem("frontend_generated_template_id")),
    );
  }, []);

  // console.log(onlyBenefitPlansList);
  // console.log(benefitProgramAndPlans)
  const templateType = JSON.parse(localStorage.getItem("formTemplateType"));
  const templateName = JSON.parse(localStorage.getItem("formTemplateName"));
  const country = JSON.parse(localStorage.getItem("formCountry"));
  const companyName = JSON.parse(localStorage.getItem("formCompanyName"));
  // console.log([templateType, templateName, country, companyName]);

  for (var cb of masterJson) {
    if (
      cb.attribute_key_name === "null_benefit_id" &&
      cb.template_type_id === templateType
    ) {
      let checkeBenefitProgram = {
        checkId: "",
        checkName: "",
      };
      checkeBenefitProgram.checkId = cb.attribute_list_valid_value;
      checkeBenefitProgram.checkName = cb.attribute_list_value_display_name;
      checkBenefitProgramList.push(checkeBenefitProgram);
    } else {
    }
  }

  for (var c of masterJson) {
    if (
      c.attribute_key_name === "benefit_plan_null" &&
      c.template_type_id === templateType
    ) {
      let checkeBenefitPlan = {
        checkId: "",
        checkName: "",
      };
      checkeBenefitPlan.checkId = c.attribute_list_valid_value;
      checkeBenefitPlan.checkName = c.attribute_list_value_display_name;
      checkBenefitPlanList.push(checkeBenefitPlan);
    } else {
    }
  }

  for (var f of masterJson) {
    if (
      f.attribute_key_name === "source_file_extension" &&
      f.template_type_id === templateType
    ) {
      let tempFileExtension = {
        fileExtensionId: "",
        fileExtensionDisplayName: "",
        sortOrder: "",
      };
      tempFileExtension.fileExtensionId = f.attribute_list_valid_value;
      tempFileExtension.fileExtensionDisplayName =
        f.attribute_list_value_display_name;
      tempFileExtension.sortOrder = f.attribute_list_value_sort_order;
      fileExtensionList.push(tempFileExtension);
    } else {
    }
  }

  for (var d of masterJson) {
    if (
      d.attribute_key_name === "source_file_date_format" &&
      d.template_type_id === templateType
    ) {
      let tempDateformat = {
        dateformatId: "",
        dateformatDisplayName: "",
        sortOrder: "",
      };
      tempDateformat.dateformatId = d.attribute_list_valid_value;
      tempDateformat.dateformatDisplayName =
        d.attribute_list_value_display_name;
      tempDateformat.sortOrder = d.attribute_list_value_sort_order;
      dateformatList.push(tempDateformat);
    } else {
    }
  }

  for (var s of masterJson) {
    if (
      s.attribute_key_name === "source_file_delimiter" &&
      s.template_type_id === templateType
    ) {
      let tempDelimiter = {
        delimiterId: "",
        delimiterDisplayName: "",
        sortOrder: "",
      };
      tempDelimiter.delimiterId = s.attribute_list_valid_value;
      tempDelimiter.delimiterDisplayName = s.attribute_list_value_display_name;
      tempDelimiter.sortOrder = s.attribute_list_value_sort_order;
      delimiterList.push(tempDelimiter);
    } else {
    }
  }

  let duplicateBenefitProgram = [];
  for (var bp of onlyBenefitPlansList) {
    let tempBenefitProgram = {
      benefitProgramId: "",
      benefitProgramDisplayName: "",
    };
    tempBenefitProgram.benefitProgramId = bp.benefit_id;
    tempBenefitProgram.benefitProgramDisplayName =
      bp.benefit_program_display_name;
    tempBenefitProgram.sortOrder = bp.attribute_list_value_sort_order;
    duplicateBenefitProgram.push(tempBenefitProgram);
  }

  const uniqueMapCid = new Map();
  for (const obj of duplicateBenefitProgram) {
    const objString = JSON.stringify(obj);
    if (!uniqueMapCid.has(objString)) {
      uniqueMapCid.set(objString, true);
      benefitProgramList.push(obj);
    }
  }

  let duplicateBenefitPlan = [];
  for (var bpl of onlyBenefitPlansList) {
    for (var benefits of benefitProgram) {
      if (bpl.benefit_id === benefits) {
        let tempBenefitPlan = {
          benefitPlanId: "",
          benefitPlanDisplayName: "",
        };
        tempBenefitPlan.benefitPlanId = bpl.benefit_plan_id;
        tempBenefitPlan.benefitPlanDisplayName = bpl.benefit_plan_display_name;
        tempBenefitPlan.sortOrder = bpl.attribute_list_value_sort_order;
        duplicateBenefitPlan.push(tempBenefitPlan);
      }
    }
  }

  const uniqueMapCid1 = new Map();
  for (const obj of duplicateBenefitPlan) {
    const objString = JSON.stringify(obj);
    if (!uniqueMapCid1.has(objString)) {
      uniqueMapCid1.set(objString, true);
      benefitPlanList.push(obj);
    }
  }

  for (var db of masterJson) {
    if (
      db.attribute_key_name === "contains_dependent" &&
      db.template_type_id === templateType
    ) {
      let contains_dependents_bool = {
        containsDependentId: "",
        containsDependentDisplayName: "",
        sortOrder: "",
      };
      contains_dependents_bool.containsDependentId =
        db.attribute_list_valid_value;
      contains_dependents_bool.containsDependentDisplayName =
        db.attribute_list_value_display_name;
      contains_dependents_bool.sortOrder = db.attribute_list_value_sort_order;
      dependent.push(contains_dependents_bool);
    } else {
    }
  }

  for (var de of masterJson) {
    if (
      de.attribute_key_name === "contains_employee" &&
      de.template_type_id === templateType
    ) {
      let contains_dependents_bool = {
        containsEmployeeId: "",
        containsEmployeeDisplayName: "",
        sortOrder: "",
      };
      contains_dependents_bool.containsEmployeeId =
        de.attribute_list_valid_value;
      contains_dependents_bool.containsEmployeeDisplayName =
        de.attribute_list_value_display_name;
      contains_dependents_bool.sortOrder = de.attribute_list_value_sort_order;
      employee.push(contains_dependents_bool);
    } else {
    }
  }

  for (var dpk of masterJson) {
    if (
      dpk.attribute_key_name === "census_source_dependent_primary_key" &&
      dpk.template_type_id === templateType
    ) {
      let contains_dependents_bool = {
        dPrimaryKeyId: "",
        dPrimaryKeyDisplayName: "",
        sortOrder: "",
      };
      contains_dependents_bool.dPrimaryKeyId = dpk.attribute_list_valid_value;
      contains_dependents_bool.dPrimaryKeyDisplayName =
        dpk.attribute_list_value_display_name;
      contains_dependents_bool.sortOrder = dpk.attribute_list_value_sort_order;
      dependentPrimarykeyList.push(contains_dependents_bool);
    } else {
    }
  }

  for (var epk of masterJson) {
    if (
      epk.attribute_key_name === "census_source_employee_primary_key" &&
      epk.template_type_id === templateType
    ) {
      let contains_dependents_bool = {
        ePrimaryKeyId: "",
        ePrimaryKeyDisplayName: "",
        sortOrder: "",
      };
      contains_dependents_bool.ePrimaryKeyId = epk.attribute_list_valid_value;
      contains_dependents_bool.ePrimaryKeyDisplayName =
        epk.attribute_list_value_display_name;
      contains_dependents_bool.sortOrder = epk.attribute_list_value_sort_order;
      employeePrimaryKeyList.push(contains_dependents_bool);
    } else {
    }
  }

  // console.log(dependentPrimarykeyList)
  // console.log(employeePrimaryKeyList)

  fileExtensionList.sort((a, b) => a.sortOrder - b.sortOrder);
  delimiterList.sort((a, b) => a.sortOrder - b.sortOrder);
  dateformatList.sort((a, b) => a.sortOrder - b.sortOrder);
  benefitProgramList.sort((a, b) => a.sortOrder - b.sortOrder);
  benefitPlanList.sort((a, b) => a.sortOrder - b.sortOrder);
  employee.sort((a, b) => a.sortOrder - b.sortOrder);
  dependent.sort((a, b) => a.sortOrder - b.sortOrder);
  employeePrimaryKeyList.sort((a, b) => a.sortOrder - b.sortOrder);
  dependentPrimarykeyList.sort((a, b) => a.sortOrder - b.sortOrder);
  benefitPlanList.sort((a, b) => {
    const nameA = a.benefitPlanDisplayName.toLowerCase();
    const nameB = b.benefitPlanDisplayName.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  benefitPlanList.reverse();
  benefitProgramList.sort((a, b) => {
    const nameA = a.benefitProgramDisplayName.toLowerCase();
    const nameB = b.benefitProgramDisplayName.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  benefitProgramList.reverse();

  useEffect(() => {
    // localStorage.setItem("benefitValues", JSON.stringify(benefit));
  });

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };

  const handleChangeBenefitProgram = (event) => {
    const {
      target: { value },
    } = event;

    setBenefitPrograms(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  const handleChangeBenefitPlan = (event) => {
    const {
      target: { value },
    } = event;

    setBenefitPlans(typeof value === "string" ? value.split(",") : value);
  };

  let navigate = useNavigate();
  const [uploadFilePath, setUploadFilePath] = useState("");

  const createTemplateS2Metadata = async (file_key) => {
    // console.log("Entered Mutation");
    console.log(file_key);
    let s1JSON = {};
    s1JSON["template_type_id"] = templateType;
    s1JSON["template_id"] = JSON.parse(
      localStorage.getItem("frontend_generated_template_id"),
    );
    s1JSON["template_display_name"] = templateName;
    s1JSON["company_id"] = companyName;
    s1JSON["country_code"] = country;
    s1JSON["userid"] = user;

    let s2JSON = {};
    s2JSON["source_file_extension"] = fileExtension;
    s2JSON["column_start_row"] = startRow;
    s2JSON["source_file_delimiter"] = delimiter;
    s2JSON["sheet_name"] = sheetName;
    s2JSON["source_file_date_format"] = dateFormat;
    s2JSON["benefit_ids"] = benefitProgram.join("+");
    s2JSON["benefit_plan_id"] = benefitPlan.join("+");
    s2JSON["contains_employees_bool"] = containsEmployee;
    s2JSON["contains_dependents_bool"] = containsDependent;
    s2JSON["census_source_employee_primary_key"] = employeePrimaryKey;
    s2JSON["census_source_dependent_primary_key"] = dependentPrimarykey;

    var finalJSON = [];

    for (let key in s2JSON) {
      let tempJSON = { ...s1JSON }; // Create a new object for each iteration
      tempJSON.attribute_key = key;
      if (s2JSON[key] === undefined || s2JSON[key] === null) {
        tempJSON.attribute_value = null;
      } else {
        tempJSON.attribute_value = s2JSON[key];
      }
      finalJSON.push(tempJSON);
    }
    console.log("fj", finalJSON);
    const uploadStep2Metadata = await API.graphql({
      query: mutations.createIntegrateTemplateAttributeDetails,
      variables: {
        create_template_params: finalJSON,
        file_id: file_key,
      },
    }).then((data) => {
      // console.log(data);
      if (data !== undefined) {
        if (
          data.data.createIntegrateTemplateAttributeDetails.lambda_status ===
          "Success"
        ) {
          // Datta Use this Column Array to feed the Step 3 dropdowns
          // data.createIntegrateTemplateAttributeDetails.source_column_names
          // console.log(
          //   data.data.createIntegrateTemplateAttributeDetails
          //     .source_column_names
          // );
          localStorage.setItem(
            "generated_template_id",
            data.data.createIntegrateTemplateAttributeDetails
              .generated_template_id,
          );
          localStorage.setItem(
            "new_file_key_s2_outbound",
            data.data.createIntegrateTemplateAttributeDetails.new_file_key,
          );
          s3ColumnData(
            data.data.createIntegrateTemplateAttributeDetails
              .source_column_names,
          );
          // console.log("right",
          // data.data.createIntegrateTemplateAttributeDetails
          //   .source_column_names,
          //
          // )
          // alert(
          //   "API Status : " +
          //     data.data.createIntegrateTemplateAttributeDetails.lambda_status
          // );
        } else {
          alert(
            "API Status : " +
              data.date.createIntegrateTemplateAttributeDetails.lambda_status,
          );
        }
      } else {
        alert("API Failed");
      }
    });
  };

  // console.log(getS3DropDownData);

  const uploadFile = async () => {
    // let resultJSON = benefit;
    let file_name =
      "ui-uploads/createTemplateS2FileUpload/" +
      "resultJSON.benefitProgram" +
      "/" +
      fileList[0].name;
    const result = await Storage.put(file_name, fileList[0], {
      contentType: fileList[0].type,
    }).then((data) => {
      console.log("Finished File Upload Process. Printing Status");
      if (data.key !== undefined) {
        setUploadFilePath(data.key);
        console.log("File Uploaded" + data.key);
        createTemplateS2Metadata(data.key);
      } else {
        console.log("The File Upload Failed");
      }
      // console.log(resultJSON);
      console.log(data);
    });
    return result;
  };

  // contains_employees_bool: "",
  // census_source_employee_primary_key: "",
  // contains_dependents_bool: "",
  // census_source_dependent_primary_key: "",
  // source_file_extension: "",
  // source_file_delimiter: "",
  // sheet_name: "",
  // column_start_row: "",
  // source_file_date_format: "",

  const handleSave = async (e) => {
    if (
      // benefit.benefitProgram < 1 ||
      // benefit.benefitPlans < 1 ||
      // benefit.sheet_name < 1 ||
      // benefit.dependents < 1
      fileExtension < 1 ||
      startRow < 1 ||
      dateFormat < 1 ||
      containsEmployee < 1 ||
      containsDependent < 1
    ) {
      e.preventDefault();
      alert("Please fill all the Required Fields.!!!");
    } else {
      e.preventDefault();
      setWait(true);
      await uploadFile();
      navigate("/integrate/createtemplates3");
      // window.location.reload();
    }
  };

  // console.log(benefitPrograms);
  const handleClear = (e) => {
    e.preventDefault();
    // setSheetName([]);
  };

  const handleBack = () => {
    navigate("/integrate/createtemplates1");
    window.location.reload();
  };

  let listStyles = {
    position: "absolute",
    top: "0",
    right: "0",
    padding: "2px",
    color: "#0000A3",
    fontSize: "25px",
  };

  // console.log(benefitProgramAndPlans);
  // console.log("modify",data);

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[14.5rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates1">
              Create Template
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/createtemplates2">
              Capture Additional Fields
            </Link>
          </div>

          {/* page Heading*/}
          <div>
            <section className="flex items-center">
              <BsBoxArrowInRight style={iconStyles} />
              <p className="text-3xl mx-1">Create Template</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex">
            {/*steps*/}
            <div className="ml-3">
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 1</p>
                <p className="text-md pb-1">Set Template Properties</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 2</p>
                <p className="font-bold text-md pb-1 ">
                  Capture Addtional Fields
                </p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 3</p>
                <p className="text-md pb-1 ">Map Columns</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 4</p>
                <p className="text-md pb-1">Map Keys To Values</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
            </div>

            {/* form*/}
            <div className="mt-4 w-[855px] mx-3 ml-[3.5rem]">
              <Paper elevation={2}>
                <div className="py-3">
                  <form className="grid grid-cols-2">
                    <div className="flex flex-col mx-14 my-3">
                      <div className=" w-full relative inline-block">
                        <FormControl
                          name="fullWidth2"
                          fullWidth
                          error={fileExtension.length > 0 ? false : true}
                        >
                          <InputLabel id="cn21">File Extension</InputLabel>
                          <Select
                            labelId="cn98"
                            id="cn44"
                            value={fileExtension}
                            label="File Extension"
                            onChange={handleFileExtensionChange}
                            className={
                              fileExtension.length > 0 ? "fill" : "empty"
                            }
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 430 } },
                            }}
                          >
                            {fileExtensionList.map((name, key) => (
                              <MenuItem key={key} value={name.fileExtensionId}>
                                {name.fileExtensionDisplayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="flex flex-col mx-14 my-3">
                      <div className="w-full relative inline-block">
                        <TextField
                          name="fullWidth3"
                          fullWidth
                          value={startRow}
                          onChange={handleStartRowChange}
                          id="startRow"
                          label="Start Row"
                          variant="outlined"
                          error={startRow.length > 0 ? false : true}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col mx-14 my-3">
                      {fileExtension === ".xls" || fileExtension === ".xlsx" ? (
                        <>
                          <TextField
                            name="fullWidth4"
                            fullWidth
                            value={sheetName}
                            onChange={handleSheetNameChange}
                            id="sheetName"
                            label="Sheet Name"
                            variant="outlined"
                            error={sheetName.length > 0 ? false : true}
                          />
                        </>
                      ) : (
                        <>
                          <FormControl
                            name="fullWidth5"
                            fullWidth
                            error={delimiter.length > 0 ? false : true}
                          >
                            <InputLabel id="cn22">Delimiter</InputLabel>
                            <Select
                              labelId="cn99"
                              id="cn45"
                              value={delimiter}
                              label="Delimiter"
                              onChange={handleDelimiterChange}
                              className={
                                delimiter.length > 0 ? "fill" : "empty"
                              }
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 430 } },
                              }}
                            >
                              {delimiterList.map((name, key) => (
                                <MenuItem key={key} value={name.delimiterId}>
                                  {name.delimiterDisplayName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </div>

                    <div className="flex flex-col mx-14 my-3">
                      <div className=" w-full relative inline-block">
                        <FormControl
                          name="fullWidth6"
                          fullWidth
                          error={dateFormat.length > 0 ? false : true}
                        >
                          <InputLabel id="cn23">Date Format</InputLabel>
                          <Select
                            labelId="cn100"
                            id="cn46"
                            value={dateFormat}
                            label="Date Format"
                            onChange={handleDateFormatChange}
                            className={dateFormat.length > 0 ? "fill" : "empty"}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 430 } },
                            }}
                          >
                            {dateformatList.map((name, key) => (
                              <MenuItem key={key} value={name.dateformatId}>
                                {name.dateformatDisplayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <hr className="h-[1px] bg-[#7A7A7A] my-10 mx-14 grid col-start-1 col-end-3" />

                    <div className="flex flex-col mx-14 my-3">
                      <div className=" w-full relative inline-block">
                        <FormControl
                          name="fullWidth7"
                          fullWidth
                          error={benefitProgram.length > 0 ? false : true}
                        >
                          <InputLabel id="cn24">Benefit Program</InputLabel>
                          <Select
                            labelId="cn101"
                            id="cn47"
                            value={benefitProgram}
                            label="Benefit Program"
                            onChange={handleBenefitProgramChange}
                            className={
                              benefitProgram.length > 0 ? "fill" : "empty"
                            }
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 430 } },
                            }}
                            multiple
                          >
                            {benefitProgramList.map((name, key) => (
                              <MenuItem key={key} value={name.benefitProgramId}>
                                {name.benefitProgramDisplayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="flex flex-col mx-14 my-3">
                      <div className=" w-full relative inline-block">
                        <FormControl
                          name="fullWidth9"
                          fullWidth
                          error={benefitPlan.length > 0 ? false : true}
                        >
                          <InputLabel id="cn26">Benefit Plan</InputLabel>
                          <Select
                            labelId="cn103"
                            id="cn49"
                            value={benefitPlan}
                            label="Benefit Plan"
                            onChange={handleBenefitPlanChange}
                            className={
                              benefitPlan.length > 0 ? "fill" : "empty"
                            }
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 430 } },
                            }}
                            multiple
                          >
                            {benefitPlanList.map((name, key) => (
                              <MenuItem key={key} value={name.benefitPlanId}>
                                {name.benefitPlanDisplayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="flex flex-col mx-14 my-3">
                      <div className=" w-full relative inline-block">
                        <FormControl
                          name="fullWidth9"
                          fullWidth
                          error={containsEmployee.length > 0 ? false : true}
                        >
                          <InputLabel id="cn26">Contains Employee?</InputLabel>
                          <Select
                            labelId="cn103"
                            id="cn49"
                            value={containsEmployee}
                            label="Contains Employee?"
                            onChange={handleContainsEmployeeChange}
                            className={
                              containsEmployee.length > 0 ? "fill" : "empty"
                            }
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 430 } },
                            }}
                          >
                            {employee.map((name, key) => (
                              <MenuItem
                                key={key}
                                value={name.containsEmployeeId}
                              >
                                {name.containsEmployeeDisplayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="flex flex-col mx-14 my-3">
                      <div className=" w-full relative inline-block">
                        <FormControl
                          name="fullWidth10"
                          fullWidth
                          error={containsDependent.length > 0 ? false : true}
                        >
                          <InputLabel id="cn27">Contains Dependent?</InputLabel>
                          <Select
                            labelId="cn104"
                            id="cn50"
                            value={containsDependent}
                            label="Contains Dependent?"
                            onChange={handleContainsDependentChange}
                            className={
                              containsDependent.length > 0 ? "fill" : "empty"
                            }
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 430 } },
                            }}
                          >
                            {dependent.map((name, key) => (
                              <MenuItem
                                key={key}
                                value={name.containsDependentId}
                              >
                                {name.containsDependentDisplayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="flex flex-col mx-14 my-3">
                      <div className=" w-full relative inline-block">
                        <FormControl name="fullWidth11" fullWidth>
                          <InputLabel id="cn28">
                            Employee Primary Key
                          </InputLabel>
                          <Select
                            labelId="cn105"
                            id="cn51"
                            value={
                              containsEmployee === "yes"
                                ? employeePrimaryKey
                                : ""
                            }
                            label="Employee Primary Key"
                            onChange={handleEmployeePrimaryKeyChange}
                            error={employeePrimaryKey.length > 0 ? false : true}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 430 } },
                            }}
                            disabled={containsEmployee === "yes" ? false : true}
                          >
                            {employeePrimaryKeyList.map((name, key) => (
                              <MenuItem key={key} value={name.ePrimaryKeyId}>
                                {name.ePrimaryKeyDisplayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="flex flex-col mx-14 my-3">
                      <div className=" w-full relative inline-block">
                        <FormControl name="fullWidth12" fullWidth>
                          <InputLabel id="cn29">
                            Dependent Primary Key
                          </InputLabel>
                          <Select
                            labelId="cn106"
                            id="cn52"
                            value={
                              containsDependent === "yes"
                                ? dependentPrimarykey
                                : ""
                            }
                            label="Dependent Primary Key"
                            onChange={handleDependentPrimaryKeyChange}
                            error={employeePrimaryKey.length > 0 ? false : true}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 430 } },
                            }}
                            disabled={
                              containsDependent === "yes" ? false : true
                            }
                          >
                            {dependentPrimarykeyList.map((name, key) => (
                              <MenuItem key={key} value={name.dPrimaryKeyId}>
                                {name.dPrimaryKeyDisplayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <hr className="h-[1px] bg-[#7A7A7A] my-10 mx-14 grid col-start-1 col-end-3" />

                    <div
                      ref={ddRef}
                      className="relative grid row-span-3 h-[110px] justify-center items-center hover:opacity-70 mx-14 border-2 border-dotted cursor-pointer"
                    >
                      <MdCloudUpload style={fileStyles} />
                      <p className="text-[#0000A3]">
                        Upload template or drag and Drop
                      </p>
                      <input
                        type="file"
                        className="opacity-0 absolute h-full w-full cursor-pointer"
                        onChange={onFileDrop}
                        required={false}
                        name="uploadedFiles"
                        accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, text/plain,.csv"
                        accepts={[".xls", ".xlsx", ".txt", ".csv"]}
                      />
                    </div>

                    <div className="mx-14 w-[315px] h-14">
                      <table className="w-[315px] h-14">
                        <thead className="text-sm w-full">
                          <tr className="flex text-gray-500 ">
                            <th className="w-[150px] ">File Name</th>
                            <th className="w-[65px]">Type</th>
                            <th className="w-[65px]">Size</th>
                            <th className="w-[30px]"></th>
                          </tr>
                        </thead>
                        <tbody className="w-full">
                          <tr>
                            <td>
                              <hr className="mx-2" />
                            </td>
                          </tr>
                          {fileList.map((file) => (
                            <tr key={file} className="flex text-gray-400 ">
                              <td className=" w-[150px]  flex justify-center">
                                {file.name.length < 10
                                  ? file.name
                                  : file.name.slice(0, 16) + "..."}
                              </td>
                              <td className=" w-[65px]  flex justify-center">
                                {file.name.split(".").slice(-1)}
                              </td>
                              <td className=" w-[65px]  flex justify-center">
                                {file.size < 1000000
                                  ? Math.floor(file.size / 1000) + "KB"
                                  : Math.floor(file.size / 1000000) + "MB"}
                              </td>
                              <td>
                                {" "}
                                <button
                                  className="w-[30px] mt-1  flex justify-center"
                                  type="button"
                                  onClick={() => {
                                    setFileList([]);
                                  }}
                                >
                                  <MdOutlineCancel />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="pr-14 pt-16 w-full flex justify-end items-center">
                      <button
                        type="submit"
                        className="ml-10 bg-[#AEB3B7] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                        onClick={handleBack}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="ml-5 bg-[#DB2748] py-[6px] px-6  text-white  rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] "
                        onClick={(e) => {
                          handleSave(e);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

          //
          // <div className="pt-10">
          //   {" "}
          //   {JSON.stringify(
          //     [
          //       fileExtension,
          //       startRow,
          //       delimiter,
          //       sheetName,
          //       dateFormat,
          //       benefitProgram,
          //       benefitPlan,
          //       containsEmployee,
          //       containsDependent,
          //       employeePrimaryKey,
          //       dependentPrimarykey,
          //     ],
          //     null,
          //     "    ",
          //   )}
          // </div>
          // <div>
          //   {fileList.map((file, i) => (
          //     <p key={i}>{file.name}</p>
          //   ))}
          // </div>

// <button
//   onClick={(e) => {
//     handleClear(e);
//   }}
//   className="ml-10 text-gray-500"
// >
//   Cancel
// </button>

// <div className="flex flex-col mx-14 my-3">
//   <div className="flex ">
//     <label className="text-lg required" htmlFor="dateInfo">
//       As of Date
//     </label>
//     <p className="text-[#0000A3] ml-1 mt-[6px] text-sm">info</p>
//   </div>
//   <input
//     type="text"
//     onChange={(e) => {
//       onChange(e);
//     }}
//     name="dateInfo"
//     className="border border-gray-300 px-2 py-[2px] w-full"
//     id="dateInfo"
//     value={benefit.dateInfo}
//     required
//   />
// </div>
//
//
//
//
//
// <div className="flex h-fit text-sm justify-between text-gray-500 font-semibold">
//   <p>File Name</p>
//   <p>Type</p>
//   <p>Size</p>
//   <p>Remove</p>
// </div>
//
// <hr />
// {fileList.map((file) => (
//   <div className="pt-2 mx-1 w-full text-sm flex justify-between  text-gray-400">
//     <p>{file.name}</p>
//     <p>{file.name.split(".").slice(-1)}</p>
//     <p>
//       {file.size < 1000000
//         ? Math.floor(file.size / 1000) + "KB"
//         : Math.floor(file.size / 1000000) + "MB"}
//     </p>
// <button
//   type="button"
//   onClick={() => {
//     setFileList([]);
//   }}
// >
// <MdOutlineCancel />
//     </button>
//   </div>
// ))}
// const handleFileRead = (e) => {
//     console.log(e)
//   const content = e.target.result;
//   const lines = content.split("\n");
//   const columnNames = lines[0].split(",");
//   setColumns(columnNames);
//     console.log(columnNames)
//   let start = 0;
//   for (var colName of columnNames) {
//     tempColumnData[colName] = start;
//     start++;
//   }
//   console.log(tempColumnData);
// };

// const getMappedOption = (optionNumber, optionValue) => {
//   let start = 0;
//   for (var key in tempColumnData) {
//     if (optionNumber === tempColumnData[key] || optionNumber === start) {
//       tempColumnData[key] = optionValue;
//     }
//     start++;
//   }
//   console.log(tempColumnData);
// };
