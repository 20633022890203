import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill } from "react-icons/pi";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import Alerts from "../../components/Alerts.jsx";
import { IContext } from "../../context/IntegrateContext.jsx";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API } from "aws-amplify";

export default function EFromUploadS1({ user, signOut }) {
  const [alert, setAlert] = useState("");
  const [wait, setWait] = useState(true);
  const [masterJson, setmasterJson] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [tempCompanyNames, setTempCompanyNames] = useState([]);

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handleTemplateTypeChange = (event) => {
    setTemplateType(event.target.value);
  };
  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  let numberStylesOn = { padding: "2px", color: "#0000A3", fontSize: "30px" };
  let numberStylesOut = { padding: "2px", color: "#AEB3B7", fontSize: "30px" };

  // const { companyNameList, templateAndbenefitprogramTypeList } = IContext();

  useEffect(() => {
    const getDropDownData = async () => {
      const dropDownData = await API.graphql({
        query: queries.readeFormTemplateMetaData,
      });
      // console.log(templateAndbenefitprogramSelection.data);integratecon

      setmasterJson(dropDownData.data.readeFormTemplateMetaData);
      setWait(false);
    };

    const getCreateTemplateCompanyNames = async () => {
      const createTemplateCompanyNames = await API.graphql({
        query: mutations.CompanyListUseridFilter,
        variables: {
          userid: user,
        },
      });
      setTempCompanyNames(
        createTemplateCompanyNames.data.CompanyListUseridFilter,
      );
      setWait(false);
    };

    getDropDownData();
    getCreateTemplateCompanyNames();

    return () => {};
  }, []);

  useEffect(() => {
    localStorage.setItem("eformTemplateName", JSON.stringify(templateName));
    localStorage.setItem("eformTemplateType", JSON.stringify(templateType));
    localStorage.setItem("eformCompanyName", JSON.stringify(companyName));
  });

  let templateTypeList = [];
  let companyNameList = [];
  let templateNameList = [];

  for (var t3 of tempCompanyNames) {
    let temp3 = {
      template_id: "",
      template_display_name: "",
    };
    temp3.template_id = t3.company_id;
    temp3.template_display_name = t3.company_name;
    companyNameList.push(temp3);
  }

  for (var t2 of masterJson) {
    let temp3 = {
      template_id: "",
      template_display_name: "",
    };
    temp3.template_id = t2.template_type_id;
    temp3.template_display_name = t2.template_type_display_name;
    templateTypeList.push(temp3);
  }

  for (var t4 of masterJson) {
    let temp3 = {
      template_id: "",
      template_display_name: "",
    };
    temp3.template_id = t4.template_id;
    temp3.template_display_name = t4.template_display_name;
    templateNameList.push(temp3);
  }

  // let templateTypeList = [];
  // const uniqueMap3 = new Map();
  // for (const obj of tempTemplateTypeList) {
  //   const objString = JSON.stringify(obj);
  //   if (!uniqueMap3.has(objString)) {
  //     uniqueMap3.set(objString, true);
  //     templateTypeList.push(obj);
  //   }
  // }
  //
  // let temBenefitProgramTypeList = [];
  // for (var t3 of templateAndbenefitprogramTypeList) {
  //   if (
  //     t3.category_name === "eform_create" &&
  //     t3.attribute_key_name === "eform_benefit_id"
  //   ) {
  //     let temp3 = {
  //       template_id: "",
  //       template_display_name: "",
  //       template_sort_order: "",
  //     };
  //     temp3.template_id = t3.attribute_list_valid_value;
  //     temp3.template_display_name = t3.attribute_list_value_display_name;
  //     temp3.template_sort_order = t3.attribute_list_value_sort_order;
  //     temBenefitProgramTypeList.push(temp3);
  //   } else {
  //   }
  // }
  //
  // let benefitProgramTypeList = [];
  // const uniqueMap4 = new Map();
  // for (const obj of temBenefitProgramTypeList) {
  //   const objString = JSON.stringify(obj);
  //   if (!uniqueMap4.has(objString)) {
  //     uniqueMap4.set(objString, true);
  //     benefitProgramTypeList.push(obj);
  //   }
  // }

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "40px" };

  let navigate = useNavigate();

  const handleSave = async (e) => {
    if (companyName < 1 || templateName < 1 || templateType < 1) {
      e.preventDefault();
      setAlert("error");
    } else {
      e.preventDefault();

      // const sendingBenefitStep1Values = await API.graphql({
      //   query: mutations.createeForm,
      //   variables: {
      //     template_display_name: templateName,
      //     template_status: "A",
      //     template_type_id: templateType,
      //     benefit_id: programType,
      //     attribute_key: "",
      //     attribute_value: "",
      //     userid: user,
      //   },
      // }).then((data) => {
      //   console.log(data);
      // });

      setAlert("success");
      navigate("/integrate/eformuploads2");
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
            input[type="checkbox"]{accent-color:#0000A3;}
          `}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[15rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/upload-select">
              Select Upload Type
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/uploadtemplateS2">
              E-Forms
            </Link>
          </div>
          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <MdOutlineAutoFixHigh style={iconStyles} />
              <p className="text-3xl mx-1">E-Form</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Successfully, Proceed to upload the file in next page"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Please fill all the fields" />
            ) : (
              ""
            )}
          </div>
          {/* form*/}
          <div className="flex mt-5 ">
            <div className="ml-3">
              <div className="flex items-center space-x-2">
                <PiNumberCircleOneFill style={numberStylesOn} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 1</p>
                  <p className="font-bold text-md pb-1 ">
                    Select Template Type
                  </p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <PiNumberCircleTwoFill style={numberStylesOut} />
                <div className="flex flex-col my-4">
                  <p className=" text-gray-600">Step 2</p>
                  <p className=" text-md pb-1 ">Submit E-Form</p>
                  <div className="w-full h-[1px] bg-gray-200" />
                </div>{" "}
              </div>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={wait}
            >
              <CircularProgress sx={{ color: "#DB2748" }} />{" "}
            </Backdrop>

            <div className="w-[615px] ml-16 mt-4">
              <Paper elevation={2}>
                <div className="px-10 pt-10">
                  <div className="flex justify-center">
                    <div className="font-medium text-2xl">Template Details</div>
                  </div>
                  <form className="ml-5 grid grid-cols-2 gap-8 mt-16">
                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS22">
                        Company Name
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS23"
                        id="UploadTemplateS24"
                        value={companyName}
                        label="Company Name"
                        onChange={handleCompanyNameChange}
                      >
                        {companyNameList.map((option, key) => (
                          <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS26">
                        Template Type
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS27"
                        id="UploadTemplateS28"
                        value={templateType}
                        label="Template Type"
                        onChange={handleTemplateTypeChange}
                      >
                        {templateTypeList.map((option, key) => (
                          <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl name="fullWidth5" fullWidth>
                      <InputLabel id="UploadTemplateS29">
                        Template Name
                      </InputLabel>
                      <Select
                        labelId="UploadTemplateS30"
                        id="UploadTemplateS31"
                        value={templateName}
                        label="Template Name"
                        onChange={handleTemplateNameChange}
                        MenuProps={{
                          PaperProps: { sx: { maxHeight: 250 } },
                        }}
                      >
                        {templateNameList.map((option, key) => (
                          <MenuItem value={option.template_id} key={key}>
                            {option.template_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <div className="pt-16 pb-12 col-start-1 col-end-3 w-full flex justify-end items-center">
                      <button
                        onClick={(e) => {
                          handleClear(e);
                        }}
                        className="ml-10 bg-gray-100 rounded-md text-gray-600 shadow-black shadow-[0_1px_4px_rgb(0,0,0,0.2)] py-[6px] px-5"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-5 bg-[#DB2748] rounded-md shadow-black shadow-[0_1px_3px_rgb(220,220,220)] py-[6px] px-7  text-white"
                        onClick={(e) => {
                          handleSave(e);
                        }}
                      >
                        {" "}
                        Next{" "}
                      </button>
                    </div>
                  </form>
                </div>
                <div>{companyName}</div>
                <div>{templateType}</div>
                <div>{templateName}</div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// let cnList = [
//   {
//     company_id: "0526980488",
//     company_name: "Company Name 3",
//   },
//   {
//     company_id: "1440717007",
//     company_name: "Company Name 5",
//   },
//   {
//     company_id: "6016972453",
//     company_name: "Company Name 7",
//   },
//   {
//     company_id: "7820244186",
//     company_name: "Company Name 9",
//   },
//   {
//     company_id: "1687991561",
//     company_name: "Company Name 11",
//   },
//   {
//     company_id: "1819445168",
//     company_name: "Company Name 13",
//   },
//   {
//     company_id: "9813131590",
//     company_name: "Company Name 15",
//   },
// ];
//
// let ttList = [
//   {
//     template_id: "Template Id 1",
//     template_display_name: "Template Display Name 1",
//     template_sort_order: "1",
//   },
//   {
//     template_id: "Template Id 2",
//     template_display_name: "Template Display Name 3",
//     template_sort_order: "4",
//   },
//   {
//     template_id: "Template Id 5",
//     template_display_name: "Template Display Name 6",
//     template_sort_order: "7",
//   },
//   {
//     template_id: "Template Id 8",
//     template_display_name: "Template Display Name 9",
//     template_sort_order: "10",
//   },
// ];
//
// let tnList = [
//   {
//     template_id: "name 1",
//     template_display_name: "Display Name 1",
//     template_sort_order: "1",
//   },
//   {
//     template_id: "name 2",
//     template_display_name: "Display Name 3",
//     template_sort_order: "4",
//   },
//   {
//     template_id: "name 5",
//     template_display_name: "Display Name 6",
//     template_sort_order: "7",
//   },
//   {
//     template_id: "name 8",
//     template_display_name: "Display Name 9",
//     template_sort_order: "10",
//   },
//   {
//     template_id: "name 11",
//     template_display_name: "Display Name 12",
//     template_sort_order: "13",
//   },
//   {
//     template_id: "name 14",
//     template_display_name: "Display Name 15",
//     template_sort_order: "16",
//   },
//   {
//     template_id: "name 17",
//     template_display_name: "Display Name 18",
//     template_sort_order: "19",
//   },
//   {
//     template_id: "name 20",
//     template_display_name: "Display Name 21",
//     template_sort_order: "22",
//   },
// ];

// <FormControl name="fullWidth5" fullWidth>
//   <InputLabel id="UploadTemplateS22">
//     Company Name
//   </InputLabel>
//   <Select
//     labelId="UploadTemplateS23"
//     id="UploadTemplateS24"
//     value={companyName}
//     label="Company Name"
//     onChange={handleCompanyNameChange}
//   >
//     {companyNameList.map((option, key) => (
//       <MenuItem value={option.company_id} key={key}>
//         {option.company_name}
//       </MenuItem>
//     ))}
//   </Select>
// </FormControl>
//
// <FormControl name="fullWidth12" fullWidth>
//   <TextField
//     id="UploadTemplateS25"
//     value={templateName}
//     label="Template Name"
//     onChange={handleTemplateNameChange}
//     variant="outlined"
//   />
// </FormControl>
//
// <FormControl name="fullWidth5" fullWidth>
//   <InputLabel id="UploadTemplateS26">
//     Template Type
//   </InputLabel>
//   <Select
//     labelId="UploadTemplateS27"
//     id="UploadTemplateS28"
//     value={templateType}
//     label="Template Type"
//     onChange={handleTemplateTypeChange}
//   >
//     {templateTypeList.map((option, key) => (
//       <MenuItem value={option.template_id} key={key}>
//         {option.template_display_name}
//       </MenuItem>
//     ))}
//   </Select>
// </FormControl>
//
// <FormControl name="fullWidth5" fullWidth>
//   <InputLabel id="UploadTemplateS29">
//     Benefit Program Type
//   </InputLabel>
//   <Select
//     labelId="UploadTemplateS30"
//     id="UploadTemplateS31"
//     value={programType}
//     label="Benefit Program Type"
//     onChange={handleProgramTypeChange}
//     MenuProps={{
//       PaperProps: { sx: { maxHeight: 250 } },
//     }}
//   >
//     {benefitProgramTypeList.map((option, key) => (
//       <MenuItem value={option.template_id} key={key}>
//         {option.template_display_name}
//       </MenuItem>
//     ))}
//   </Select>
// </FormControl>
