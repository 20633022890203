import { BsBoxes } from "react-icons/bs";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alerts from "../../components/Alerts.jsx";
import { React, useState, useEffect, useRef } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { API } from "aws-amplify";
import * as queries from "../../graphql/queries.js";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as mutations from "../../graphql/mutations.js";

export default function ManageReconciliations({ user, signOut }) {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [alert, setAlert] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [wait, setWait] = useState(true);

  const allReconDetails = async () => {
    const reconDetails = await API.graphql({
      query: queries.readReportReconDetailsManageRecon,
    });
    let tempRows = reconDetails.data.readReportReconDetailsManageRecon;
    console.log(tempRows);
    setRows(tempRows);
    setWait(false);
  };

  const updateAdminComments = async () => {
    setWait(true);
    let request_params = editedRows.map(
      ({ row_id, administrator_comments, report_request_id }) => ({
        row_id,
        administrator_comments,
        report_request_id,
      })
    );

    console.log(request_params);
    const updatePublishData = await API.graphql({
      query: mutations.updatePublishedDemographicProviderReconDetails,
      variables: {
        publish_recon_data: request_params,
        user_id: user,
      },
    }).then((data) => {
      console.log(data);
      setWait(false);
      alert("Finished Publishing");
    });
  };

  const handleSave = (e) => {
    console.log(editedRows);
    updateAdminComments();
    setAlert("success");
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    allReconDetails();
    setLoading(false);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1300px",
    height: "700px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "compay_name",
      renderHeader: () => <strong>{"Company"}</strong>,
      width: 150,
    },
    {
      field: "recon_type_display_name",
      renderHeader: () => <strong>{"Recon Type"}</strong>,
      width: 150,
    },
    {
      field: "input_file_id_1_template_type_name",
      renderHeader: () => <strong>{"Template Type 1"}</strong>,
      width: 150,
    },
    {
      field: "input_file_id_1_name",
      renderHeader: () => <strong>{"File 1 Name"}</strong>,
      width: 150,
    },
    {
      field: "input_file_id_1_template_name",
      renderHeader: () => <strong>{"Template Name 1"}</strong>,
      width: 150,
    },
    {
      field: "input_file_id_2_template_type_name",
      renderHeader: () => <strong>{"Template Type 2"}</strong>,
      width: 150,
    },
    {
      field: "input_file_id_2_name",
      renderHeader: () => <strong>{"File 2 Name"}</strong>,
      width: 150,
    },
    {
      field: "input_file_id_2_template_name",
      renderHeader: () => <strong>{"Template Name 2"}</strong>,
      width: 150,
    },
    {
      field: "created_dt",
      renderHeader: () => <strong>{"Created Date"}</strong>,
      width: 150,
    },
    {
      field: "last_publish_dt",
      renderHeader: () => <strong>{"Last Published Date"}</strong>,
      width: 150,
    },
    {
      field: "create_userid",
      renderHeader: () => <strong>{"User Id"}</strong>,
      width: 150,
    },
    {
      field: "publish_userid",
      renderHeader: () => <strong>{"Published User Id"}</strong>,
      width: 150,
    },
    {
      field: "report_request_id",
      renderHeader: () => <strong>{"Report Request Id"}</strong>,
      width: 150,
    },
  ];

  const columns1 = [
    { field: "recon_status", headerName: "Recon Status", width: 250 },
    {
      field: "administrator_comments",
      type: "string",
      headerName: "Admin Comments",
      width: 250,
      editable: true,
      cellClassName: (params) => {
        if (params.value === null) {
          return "empty";
        }
        if (params.value.length > 0) {
          return "fill";
        }
      },
    },
    {
      field: "recon_status_detail",
      headerName: "Recon Status Details",
      width: 150,
    },
    { field: "snap_dt", headerName: "Snap Date", width: 150 },
    {
      field: "emp_join_key_value",
      headerName: "Employee Join Key Value",
      width: 150,
    },
    {
      field: "provider_join_key_value",
      headerName: "Provider Join Key Value",
      width: 150,
    },
    { field: "country", headerName: "Country", width: 150 },
    { field: "emplid", headerName: "Employee Id", width: 150 },
    { field: "provider_emplid", headerName: "Provider Employ Id", width: 150 },
    { field: "ee_emplid", headerName: "EE Employee Id", width: 150 },
    {
      field: "provider_benefit_id",
      headerName: "Provider Benefit Id",
      width: 150,
    },
    {
      field: "provider_plan_display_name",
      headerName: "Provider Plan Display Name",
      width: 150,
    },
    {
      field: "provider_national_id",
      headerName: "Provider National Id",
      width: 150,
    },
    { field: "national_ids", headerName: "National Ids", width: 150 },
    {
      field: "provider_first_name",
      headerName: "Provider First Name",
      width: 150,
    },
    { field: "ee_first_name", headerName: "EE First Name", width: 150 },
    {
      field: "provider_middle_name",
      headerName: "Provider Middle Name",
      width: 150,
    },
    { field: "ee_middle_name", headerName: "EE Middle Name", width: 150 },
    {
      field: "provider_last_name",
      headerName: "Provider Last Name",
      width: 150,
    },
    { field: "ee_last_name", headerName: "EE Last Name", width: 150 },
    { field: "provider_date_of_birth", headerName: "Provider DOB", width: 150 },
    { field: "ee_date_of_birth", headerName: "EE DOB", width: 150 },
    {
      field: "provider_coverage_begin_date",
      headerName: "Provider Coverage Begin Date",
      width: 150,
    },
    {
      field: "provider_coverage_end_date",
      headerName: "Provider Coverage End Date",
      width: 150,
    },
    {
      field: "provider_policy_id",
      headerName: "Provider Policy Id",
      width: 150,
    },
    {
      field: "provider_vendor_name",
      headerName: "Provider Vendor Name",
      width: 150,
    },
    {
      field: "ee_recent_hire_date",
      headerName: "EE Recent Hire Date",
      width: 150,
    },
    {
      field: "ee_termination_date",
      headerName: "EE Termination Date",
      width: 150,
    },
    {
      field: "ee_country_entry_date",
      headerName: "EE Country Entry Date",
      width: 150,
    },
    {
      field: "ee_target_hr_status_code",
      headerName: "EE Target HR Status Code",
      width: 150,
    },
    {
      field: "ee_source_hr_status_code",
      headerName: "EE Source HR Status Code",
      width: 150,
    },
    { field: "ee_job_family", headerName: "EE Job Family", width: 150 },
    {
      field: "ee_target_employee_class",
      headerName: "EE Target Employee Class",
      width: 150,
    },
    {
      field: "ee_source_employee_class",
      headerName: "EE Source Employee Class",
      width: 150,
    },
  ];

  const handleDeleteRow = () => {
    console.log(selectedRows);
    let newRows = rows.filter(
      (row) => !selectedRows.includes(row.report_request_id)
    );
    setRows(newRows);
    setAlert("error");
  };

  const handleSelectedRows = (row) => {
    setSelectedRows(row);
  };

  const [rows1, setrows1] = useState([]);

  const handleUpdateRow = async (e) => {
    setOpen(true);
    console.log(selectedRows[0]);
  };

  const allUpdateReconDetails = async (rowId) => {
    const updateReconRow = await API.graphql({
      query: queries.readPublishedDemographicProviderReconDetails,
      variables: { request_id: rowId },
    });
    console.log(
      updateReconRow.data.readPublishedDemographicProviderReconDetails
    );
    let tempRows1 =
      updateReconRow.data.readPublishedDemographicProviderReconDetails;
    console.log("hower", tempRows1);
    setrows1(tempRows1);
  };

  useEffect(() => {
    setLoading(true);
    allUpdateReconDetails(selectedRows[0]);
    setLoading(false);
    return () => {};
  }, [selectedRows[0]]);

  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "50px" };

  let editedRows = [];
  let backendRows1 = [];

  // backendRows1 = rows1;
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    editedRows.push(newRow);
    // console.log(editedRows);
    // let unEditedRows = backendRows1.filter((row) => row.id !== newRow.id);
    // unEditedRows.push(updatedRow);
    // backendRows1 = unEditedRows;
    // console.log(backendRows1);
    return updatedRow;
  };

  const [action, setAction] = useState(false);

  let actionRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!actionRef.current.contains(e.target)) {
        setAction(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;} 
          .css-1si6n65{overflow-y: hidden!important;} 
          .css-frlfct{color:#9ca3af!important;} 
          .css-y599qu{color:#6b7280!important;}`}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex w-full">
          <Sidebar />
        <div className=" xl:ml-[3rem] 2xl:ml-[15rem] h-fit w-[855px] ">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/report" className="px-2">
              Report
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/report/manage-reconciliations">
              Manage Reconciliations
            </Link>
          </div>

          {/* page Heading*/}
          <div className="flex justify-between w-[1050px]">
            <section className="flex items-center">
              <BsBoxes style={iconStyles} />
              <p className="text-3xl mx-1">Manage Reconciliations</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Successfully, added your comments"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Deleted the recon row" />
            ) : (
              ""
            )}
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>
          <div className="flex items-center space-x-3 justify-end w-[1050px] mr-4">
            <div className="flex justify-end mt-1">
              <section ref={actionRef}>
                <div className="flex w-full justify-center items-center">
                  <div className="flex justify-center items-center">
                    <div
                      onClick={() => setAction((prev) => !prev)}
                      className="hover:cursor-pointer px-[10px] py-2 text-md border border-gray-400 bg-gray-100 flex items-center"
                    >
                      Actions
                      <div className="pl-2">
                        <AiOutlineCaretDown />
                      </div>
                    </div>
                    {action && (
                      <div className="hover:cursor-pointer absolute mt-[9rem] ml-1 flex z-[1] flex-col justify-center w-[98.4667px] bg-white shadow-lg py-4 ">
                        <button
                          disabled={selectedRows.length === 1 ? false : true}
                          onClick={handleUpdateRow}
                          className=" capitalize text-md hover:bg-gray-200 font-medium text-center"
                        >
                          Update
                        </button>
                        <button
                          onClick={handleDeleteRow}
                          className=" capitalize text-md hover:bg-gray-200 font-medium text-center"
                        >
                          delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>

          <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <DataGrid
                style={{
                  maxHeight: "600px",
                  maxWidth: "1300px",
                }}
                rowHeight={30}
                rows={rows1}
                getRowId={(row) => row.row_id}
                // rows={rows1.map((item, index) => ({ id: index + 1, ...item }))}
                columns={columns1}
                processRowUpdate={processRowUpdate}
                stopEditingWhenCellsLoseFocus={true}
                onCellEditStop={(params, event) => {
                  if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                    event.defaultMuiPrevented = true;
                  }
                  if (params.reason === GridCellEditStopReasons.escapeKeyDown) {
                    event.defaultMuiPrevented = true;
                  }
                  if (params.reason === GridCellEditStopReasons.tabKeyDown) {
                    event.defaultMuiPrevented = true;
                  }
                  if (
                    params.reason === GridCellEditStopReasons.shiftTabKeyDown
                  ) {
                    event.defaultMuiPrevented = true;
                  }
                }}
                sx={{
                  "& .empty": {
                    backgroundColor: "#FCF3F2",
                    color: "#CC301A",
                    fontWeight: "600",
                  },
                  "& .fill": {
                    backgroundColor: "#EBF7DA",
                    color: "#2E5C0E",
                    fontWeight: "600",
                  },
                }}
                // hideFooter
                // hideFooterPagination
                // hideFooterSelectedRowCount
              />
              <div className="flex justify-end w-full">
                <button
                  className="ml-10 mt-4 bg-[#0000A3] px-6 py-[6px] text-white"
                  onClick={(e) => {
                    setOpen(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="ml-10 mt-4 bg-[#0000A3] px-6 py-[6px] text-white"
                  onClick={(e) => {
                    handleSave(e);
                  }}
                >
                  Publish
                </button>
              </div>
            </Box>
          </Modal>

          <div className="h-[450px] w-[1050px] pt-8 mr-4">
            <DataGrid
              style={{ overflowY: "scroll" }}
              getRowId={(row) => row.report_request_id}
              rows={rows}
              checkboxSelection
              columns={columns}
              onRowSelectionModelChange={(row) => handleSelectedRows(row)}
              initialState={{
                sorting: {
                  sortModel: [{ field: "last_publish_dt", sort: "desc" }],
                },
              }}

              // hideFooter
              // hideFooterPagination
              // hideFooterSelectedRowCount
            />
          </div>
        </div>
      </div>
    </>
  );
}

// const data = [
//   {
//     id: 1,
//     company: "Company1",
//     reconType: "Recon Type 1",
//     report1: "File Name 1",
//     report2: "File Name 1",
//     date: "YYYY-MM-DD",
//     userId: "User 1",
//   },
//   {
//     id: 2,
//     company: "Company3",
//     reconType: "Recon Type 4",
//     report1: "File Name 2",
//     report2: "File Name 2",
//     date: "YYYY-MM-DD",
//     userId: "User 7",
//   },
//   {
//     id: 8,
//     company: "Company9",
//     reconType: "Recon Type 10",
//     report1: "File Name 2",
//     report2: "File Name 2",
//     date: "YYYY-MM-DD",
//     userId: "User 13",
//   },
//   {
//     id: 14,
//     company: "Company15",
//     reconType: "Recon Type 16",
//     report1: "File Name 1",
//     report2: "File Name 1",
//     date: "YYYY-MM-DD",
//     userId: "User 19",
//   },
//   {
//     id: 20,
//     company: "Company21",
//     reconType: "Recon Type 22",
//     report1: "File Name 1",
//     report2: "File Name 1",
//     date: "YYYY-MM-DD",
//     userId: "User 25",
//   },
//   {
//     id: 26,
//     company: "Company27",
//     reconType: "Recon Type 28",
//     report1: "File Name 1",
//     report2: "File Name 1",
//     date: "YYYY-MM-DD",
//     userId: "User 31",
//   },
//   {
//     id: 32,
//     company: "Company33",
//     reconType: "Recon Type 34",
//     report1: "File Name 1",
//     report2: "File Name 1",
//     date: "YYYY-MM-DD",
//     userId: "User 37",
//   },
// ];
//
//
// let itr = 0;
// for (var item of tempRows) {
//   item.id = itr + 1;
//   itr++;
// }
// console.log(tempRows);
// setRows(tempRows);
// console.log(rows);
//
//
//
//
// <Button onClick={handleOpen}>Open modal</Button>
// <Modal
//   open={open}
//   onClose={handleClose}
//   aria-labelledby="modal-modal-title"
//   aria-describedby="modal-modal-description"
// >
//   <Box sx={style}>
//     <Typography id="modal-modal-title" variant="h6" component="h2">
//       Text in a modal
//     </Typography>
//     <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//       Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
//     </Typography>
//   </Box>
// </Modal>
