import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IContext } from "../../context/IntegrateContext.jsx";
import { BsEject } from "react-icons/bs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { MdFilterListAlt } from "react-icons/md";
import Navbar from "../../components/Navbar.jsx";
import Sidebar from "../../components/Sidebar.jsx";
import Alerts from "../../components/Alerts.jsx";
import { MdCloudUpload } from "react-icons/md";
import { Storage } from "aws-amplify";
import * as mutations from "../../graphql/mutations.js";
import { API } from "aws-amplify";
import Paper from "@mui/material/Paper";
import { CContext } from "../../context/CompanyContext.jsx";
import { BsBoxArrowInRight } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill } from "react-icons/pi";
import * as queries from "../../graphql/queries.js";

export default function UploadTemplateS1({ user, signOut }) {
  let iconStyles = { padding: "2px", color: "#DB2748", fontSize: "40px" };

  const [wait, setWait] = useState(true);
  const [alert, setAlert] = useState("");
  const [template, setTemplate] = useState({
    type: "",
    companyName: "",
    name: "",
  });
  const [companyName, setcompanyName] = useState("");
  const [templateType, settemplateType] = useState("");
  const [templateName, settemplateName] = useState("");
  const [masterJson, setMasterJson] = useState([]);

  useEffect(() => {
    const getUploadTemplateValues = async () => {
      const utv = await API.graphql({
        query: mutations.IntegrateTemplateOverviewFilter,
        variables: {
          userid: user,
        },
      });
      setMasterJson(utv.data.IntegrateTemplateOverviewFilter);
      setWait(false);
    };

    getUploadTemplateValues();
  }, []);

  const { templateSubmissionList, tempCompanyNames, masterJsonWait } =
    IContext();
    // console.log(masterJson)

  let companyNameList = [];
  for (var cn of tempCompanyNames) {
    let tempCompanyName = {
      company_id: "",
      company_name: "",
    };
    tempCompanyName.company_id = cn.company_id;
    tempCompanyName.company_name = cn.company_name;
    companyNameList.push(tempCompanyName);
  }

  const getDateAndTime = () => {
    let currentDateAndTime = Date();
    // setInterval(getDateAndTime, 1000); THIS IS BREAKING THE CODE. MAKING THE BROWSER SNAP
    return currentDateAndTime;
  };
  const handleCompanyNameChange = (event) => {
    setcompanyName(event.target.value);
  };
  const handleTemplateTypeChange = (event) => {
    settemplateType(event.target.value);
  };
  const handleTemplateNameChange = (event) => {
    settemplateName(event.target.value);
  };
  const [date, setDate] = useState("");

  // const updateUploadTemplateData = (uploadTemplateData) => {
  //   template.type = uploadTemplateData?.type;
  //   template.name = uploadTemplateData?.name;
  //   template.method = uploadTemplateData?.method;
  //   template.companyName = uploadTemplateData?.companyName;
  // };

  // useEffect(() => {
  //   const unParsedUploadTemplateData = localStorage.getItem("uploadTemplate");
  //   const uploadTemplateData = JSON.parse(unParsedUploadTemplateData);
  //   // updateUploadTemplateData(uploadTemplateData);
  // }, []);

  useEffect(() => {
    localStorage.setItem("uploadTemplate", JSON.stringify(template));
  });

  // const onChange = (e) => {
  //   setTemplate({ ...template, [e.target.name]: e.target.value });
  // };

  let tempTemplateTypeList = [];
  for (var t of masterJson) {
    if (t.company_id === companyName) {
        // console.log(t)
      let temp1 = {
        template_type_id: "",
        template_type_display_name: "",
      };
      temp1.template_type_id = t.template_type_id;
      temp1.template_type_display_name = t.template_type_display_name;
      tempTemplateTypeList.push(temp1);
    } else {
    }
  }
    // console.log(tempTemplateTypeList)

  let templateTypeList = [];
  const uniqueMap = new Map();
  for (const obj of tempTemplateTypeList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap.has(objString)) {
      uniqueMap.set(objString, true);
      templateTypeList.push(obj);
    }
  }
    // console.log(templateTypeList)
  // console.log("mj", masterJson);
  //
  let tempTemplateNameList = [];
  for (var t2 of masterJson) {
    if (
      t2.company_id === companyName &&
      t2.template_type_id === templateType &&
      t2.template_id !== null
    ) {
      let temp3 = {
        template_id: "",
        template_display_name: "",
      };
      temp3.template_id = t2.template_id;
      temp3.template_display_name = t2.template_display_name;
      tempTemplateNameList.push(temp3);
    } else {
    }
  }

  let templateNameList = [];
  const uniqueMap3 = new Map();
  for (const obj of tempTemplateNameList) {
    const objString = JSON.stringify(obj);
    if (!uniqueMap3.has(objString)) {
      uniqueMap3.set(objString, true);
      templateNameList.push(obj);
    }
  }

  let navigate = useNavigate();

  const handleSave = async (e) => {
    if (
      companyName < 1 ||
      templateName < 1 ||
      templateType < 1 ||
      fileList.length < 1
    ) {
      e.preventDefault();
      setAlert("error");
    } else {
      e.preventDefault();
      setAlert("success");
      await uploadFile();
      setcompanyName("");
      settemplateName("");
      settemplateType("");
      setDate("");
      setFileList([]);
      setAlert("");
      // window.location.reload(false);
    }
  };

  const handleClear = (e) => {
    // window.location.reload(false)
    e.preventDefault();
    setTemplate({
      type: "",
      name: "",
      companyName: "",
    });
    setDate("");
    setFileList([]);
  };

  let listStyles = {
    position: "absolute",
    top: "0",
    right: "0",
    padding: "2px",
    color: "#0000A3",
    fontSize: "25px",
  };

  const [fileStatus, setFileStatus] = useState(false);
  const [fileList, setFileList] = useState([]);

  const uploadFileMetadata = async (requestJSON, fileKey) => {
    let uploadMetaJSON = {
      company_id: requestJSON.companyName,
      template_id: requestJSON.name,
      as_of_date: requestJSON.as_of_date,
      userid: user,
      ingestion_method: "UI Upload",
      user_filename: "public/" + fileKey,
    };
    const uploadIntegrateMetadata = await API.graphql({
      query: mutations.createIntegrateUploadDetails,
      variables: {
        upload_metadata: uploadMetaJSON,
      },
    }).then((data) => {
      if (data.createIntegrateUploadDetails !== "Success") {
        console.log(data);
        // setAlert("error");
      } else {
        // setAlert("success");
        console.log(data);
      }
    });
  };

  const uploadFile = async () => {
    let tempTemplate = {
      type: templateType,
      companyName: companyName,
      name: templateName,
    };
    let resultJSON = tempTemplate;
    let isoString = date.toISOString();
    let datePortion = isoString.substring(0, 10);
    resultJSON.as_of_date = datePortion;

    resultJSON.file_name =
      "ui_uploads/" +
      resultJSON.companyName +
      "/" +
      resultJSON.name +
      "/" +
      fileList[0].name;
    const result = await Storage.put(resultJSON.file_name, fileList[0], {
      contentType: fileList[0].type,
    }).then((data) => {
      console.log("Finished File Upload Process. Printing Status");
      if (data.key !== undefined) {
        console.log(data);
        // alert("Finished The File Upload");
        // alert("success")
        uploadFileMetadata(resultJSON, data.key);
      } else {
        // alert("The File Upload Failed");
        setAlert("error");
      }
      console.log(resultJSON);
      console.log(data);
    });
    setFileStatus(true);
    // console.log(21, result);
  };

  const [removefiles, setRemovefiles] = useState([]);

  let tableIconsStyles = { padding: "2px", color: "#9ca3af" };
  const [caret, setCaret] = useState(false);

  const ddRef = useRef(null);
  const onFileDrop = (e) => {
    const files = e.target.files[0];
    if (files) {
      const updatedList = [files];
      setFileList(updatedList);
      // console.log(updatedList);
    }
  };

  const handleCheckBox = (checked, e) => {
    let fileName = e.target.value;
    if (checked) {
      removefiles.push(fileName);
    } else if (!checked) {
      removefiles.splice(
        removefiles.findIndex((file) => file === fileName),
        1,
      );
    }
    // console.log("Remove Files in handleCheckBox", removefiles);
  };

  const handleRemoveFile = () => {
    let files = fileList.slice();
    let rfiles = removefiles.slice();
    for (let f of rfiles) {
      let filename = f;
      files.splice(
        files.findIndex((file) => file.name === filename),
        1,
      );
      setFileList(files);
    }
    setRemovefiles([]);
    rfiles = removefiles.slice();
  };

  let fileStyles = {
    color: "#0000A3",
    fontSize: "50px",
    margin: "auto",
  };

  // console.log([template, JSON.stringify(date), fileList]);

  return (
    <>
      {" "}
      <style>{`input::-webkit-calendar-picker-indicator {opacity: 0;}
            .required:after{content: "*"; color: red;}
// input[type="checkbox"] {
//     -webkit-appearance: none;
//     -moz-appearance: none;
// }
          `}</style>
      <Navbar user={user} signOut={signOut} />
      <div className="flex">
          <Sidebar />
        <div className="2xl:ml-[15rem] ml-12">
          {/* Navigation Links*/}
          <div className="flex text-[#0000A3] mt-4 mb-2">
            <Link to="/integrate" className="px-2">
              Integrate
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/upload-select">
              Upload Type Selection
            </Link>
            <p className="">{">"}</p>
            <Link className="px-2" to="/integrate/uploadtemplateS1">
              Upload File
            </Link>
          </div>
          {/* page Heading*/}
          <div className="flex justify-between">
            <section className="flex items-center">
              <BsEject style={iconStyles} />
              <p className="text-3xl mx-1">Upload</p>
              {/* <p className="text-[#0000A3] mt-2">info</p> */}
            </section>
            {alert === "success" ? (
              <Alerts
                severity="success"
                info="Your file has been received, visit the manage reports page to track status"
              />
            ) : alert === "error" ? (
              <Alerts severity="error" info="Try again later" />
            ) : (
              ""
            )}
          </div>
          {/* form*/}

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait}
          >
            <CircularProgress sx={{ color: "#DB2748" }} />{" "}
          </Backdrop>

          <div className="flex">
            <div className="ml-3">
              <div className="flex flex-col my-4">
                <p className="font-bold text-md pb-1 ">Upload File</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div>
              {/* <div className="flex flex-col my-4">
                <p className=" text-gray-600">Step 2</p>
                <p className="text-md pb-1 ">Upload or Complete E-Form</p>
                <div className="w-full h-[1px] bg-gray-200" />
              </div> */}
            </div>
            <div className="mt-4 w-[700px] ml-10">
              <Paper elevation={2}>
                <form className="grid grid-cols-2 px-8 pt-4">
                  <div className="flex flex-col mr-10 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={companyName.length > 0 ? false : true}
                      >
                        <InputLabel id="cn13">Company Name</InputLabel>
                        <Select
                          labelId="cn5"
                          id="cn30"
                          value={companyName}
                          label="Company Name"
                          onChange={handleCompanyNameChange}
                          className={companyName.length > 0 ? "fill" : "empty"}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                        >
                          {companyNameList.map((name, key) => (
                            <MenuItem key={key} value={name.company_id}>
                              {name.company_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="flex flex-col mr-10 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={templateType.length > 0 ? false : true}
                      >
                        <InputLabel id="cn13">Template Type</InputLabel>
                        <Select
                          labelId="cn5"
                          id="cn30"
                          value={templateType}
                          label="Template Type"
                          onChange={handleTemplateTypeChange}
                          className={templateType.length > 0 ? "fill" : "empty"}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                        >
                          {templateTypeList.map((name, key) => (
                            <MenuItem key={key} value={name.template_type_id}>
                              {name.template_type_display_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="flex flex-col mr-10 my-3">
                    <div className=" w-full relative inline-block">
                      <FormControl
                        name="fullWidth1"
                        fullWidth
                        error={templateName.length > 0 ? false : true}
                      >
                        <InputLabel id="cn13">Template Name</InputLabel>
                        <Select
                          labelId="cn5"
                          id="cn30"
                          value={templateName}
                          label="Template Name"
                          onChange={handleTemplateNameChange}
                          className={templateName.length > 0 ? "fill" : "empty"}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 430 } } }}
                        >
                          {templateNameList.map((name, key) => (
                            <MenuItem key={key} value={name.template_id}>
                              {name.template_display_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="flex flex-col mr-10 my-3">
                    <DatePicker
                      label="As Of Date"
                      name="date"
                      value={date}
                      onChange={(date) => setDate(date)}
                      format="YYYY-DD-MM"
                    />
                  </div>

                  <div className="col-span-2">
                    <div className="h-fit">
                      <p className="text-sm py-1 pb-2 text-gray-400">
                        Drag and drop files you want to upload here, or click
                        below.
                      </p>
                      <div
                        ref={ddRef}
                        className="relative grid w-full h-[116px] justify-center items-center hover:opacity-70 border-2 border-dotted cursor-pointer"
                      >
                        <MdCloudUpload style={fileStyles} />
                        <p className="text-[#0000A3]">
                          Upload template or drag and Drop
                        </p>
                        <input
                          type="file"
                          className="opacity-0 absolute h-full w-full cursor-pointer"
                          // value={""}
                          onChange={onFileDrop}
                          name="uploadedFiles"
                          // accept=".xls,.xlsx,.txt,.csv"
                          accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, text/plain,.csv"
                          accepts={[".xls", ".xlsx", ".txt", ".csv"]}
                        />
                      </div>
                    </div>

                    <div className="flex py-8 justify-end">
                      <input
                        type="button"
                        name="deleteFiles"
                        value="Remove"
                        className="border text-[#636161] text-sm border-[#636161] bg-[#E8E8E8] px-5 py-1"
                        onClick={() => handleRemoveFile()}
                      />
                    </div>
                    <div>
                      <table className="w-full">
                        <thead className="border-b border-black">
                          <tr className="flex space-x-2 mx-2 text-gray-500 mb-2">
                            <th className="flex justify-between h-[20px] w-full">
                              <p className="text-sm">Filename</p>
                              <div className="flex">
                                <div onClick={() => setCaret((prev) => !prev)}>
                                  {caret ? (
                                    <AiOutlineCaretUp
                                      style={tableIconsStyles}
                                    />
                                  ) : (
                                    <AiOutlineCaretDown
                                      style={tableIconsStyles}
                                    />
                                  )}
                                </div>
                                <div>
                                  <MdFilterListAlt style={tableIconsStyles} />
                                </div>
                              </div>
                            </th>
                            <th className="flex justify-between h-[20px] w-[80px]">
                              <p className="text-sm">Type</p>
                              <div className="flex">
                                <div onClick={() => setCaret((prev) => !prev)}>
                                  {caret ? (
                                    <AiOutlineCaretUp
                                      style={tableIconsStyles}
                                    />
                                  ) : (
                                    <AiOutlineCaretDown
                                      style={tableIconsStyles}
                                    />
                                  )}
                                </div>
                                <div>
                                  <MdFilterListAlt style={tableIconsStyles} />
                                </div>
                              </div>
                            </th>
                            <th className="flex justify-between h-[20px] w-[80px]">
                              <p className="text-sm">Size</p>
                              <div className="flex">
                                <div onClick={() => setCaret((prev) => !prev)}>
                                  {caret ? (
                                    <AiOutlineCaretUp
                                      style={tableIconsStyles}
                                    />
                                  ) : (
                                    <AiOutlineCaretDown
                                      style={tableIconsStyles}
                                    />
                                  )}
                                </div>
                                <div>
                                  <MdFilterListAlt style={tableIconsStyles} />
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          <div className="max-h-[209px] overflow-y-auto">
                            {fileList.map((file, i) => (
                              <div
                                key={file.name + i}
                                className={`${
                                  i % 2 === 0 ? "flex " : "flex bg-gray-50"
                                }`}
                              >
                                <div className="text-gray-400 text-sm  ml-2 my-2 h-full w-full">
                                  <input
                                    // checked={onSelectAll ? true : false}
                                    type="checkbox"
                                    name="selectAllFiles"
                                    id="selectAllFiles"
                                    className="border border-black w-3 mr-1"
                                    value={file.name}
                                    onChange={(e) =>
                                      handleCheckBox(e.target.checked, e, i)
                                    }
                                  />
                                  {file.name.length > 50
                                    ? file.name.slice(0, 50) + "..."
                                    : file.name}
                                </div>
                                <div className="text-gray-400 text-sm my-2 ml-2 h-full w-[80px]">
                                  {file.name.split(".").slice(-1)}
                                </div>
                                <div className="text-gray-400 text-sm my-2 ml-2 h-full w-[80px]">
                                  {file.size < 1000000
                                    ? Math.floor(file.size / 1000) + "KB"
                                    : Math.floor(file.size / 1000000) + "MB"}
                                </div>
                              </div>
                            ))}
                          </div>
                        </tbody>
                      </table>
                    </div>

                    <div className="pt-20 w-full flex justify-end items-center pb-4">
                      <button
                        // pt-52 pl-[36rem]
                        onClick={(e) => {
                          handleClear(e);
                        }}
                        className="ml-10  text-gray-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-5 bg-[#0000A3] py-[6px]  px-6  text-white"
                        onClick={(e) => {
                          handleSave(e);
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </Paper>
            </div>
          </div>

          <p className="mt-14 text-center text-gray-400">
            Uploaded file(s) must be in the same format specified in template.
            <br />
            Please go to{" "}
            <span className="text-[#13B7FE]">'Manage Template'</span> if there
            is an update required for an existing template.
          </p>
        </div>
      </div>
    </>
  );
}

          // <div className="pt-10">
          //   {" "}
          //   {JSON.stringify(
          //     [companyName, templateType, templateName, date],
          //     null,
          //     "    ",
          //   )}
          // </div>
