// React Router Dom
import { Route, Routes, Link, useNavigate, Navigate } from "react-router-dom";
import { React, useState, useEffect } from "react";
import LoginImage from "./assets/Acropolis Logo.png";
import { Authenticator,useAuthenticator,Heading,View,useTheme,Image,Text,Button} from "@aws-amplify/ui-react";
// Context Provider
import { CContext, CompanyContextProvider } from "./context/CompanyContext.jsx";
import { IntegrateContextProvider } from "./context/IntegrateContext.jsx";

// Pages
import {
  CreateCompanyPage,
  LandingPage,
  ManageCompaniesPage,
  ManagePermissionsPage,
  CreateUserPage,
  UpdateCompanyPage
} from "./pages/companypages/index.jsx";

import { DashboardViewPage } from "./pages/dashboardpages/index.jsx";

import {
  IntegrateLandingPage,
  CreateTemplateS1,
  CreateTemplateS2,
  CreateTemplateS3,
  CreateTemplateS4,
  ManageIntegrateTemplate,
  UploadTemplateS1,
  UploadTemplateS2,
  UploadTemplateS3,
  IntegrateUploadSelect,
  IntegrateSelection,
  EFormUploadS1,
  EFormUploadS2,
  ManageFiles,
} from "./pages/integratepages/index.jsx";

import {
  ReportLandingPage,
  ReconcilePage,
  ReconcileInstructionsPage,
  ManageReconciliations,
  ReconcileSelect,
  RunReport,
  StandardReportSelect,
  ManageReports,
} from "./pages/reportpages/index.jsx";

import {
  DesignLandingPage,
  CreateBenefitS1,
  CreateBenefitS2,
  BenefitInventory,
  UpdateBenefit
} from "./pages/designpages/index.jsx";

import Login from "./pages/Login.jsx";
import InDev from "./pages/InDev.jsx";
import NotFound from "./pages/NotFound.jsx";

// Materia UI Context
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Amplify
// import withAuthentication from './withAuthentication.js';
// import { Authenticator } from "@aws-amplify/ui-react";
import { Auth, Hub } from "aws-amplify";
import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";
import * as queries from "../src/graphql/queries.js";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
API.configure(awsExports);

const AUTO_LOGOUT_TIME = 60 * 60 * 1000; // 30 minutes of inactivity
// const AUTO_LOGOUT_TIME =  60 * 1000; // 30 minutes of inactivity

// API calls
export default function App() {
  let navigate = useNavigate();

  const [userState, setUserState] = useState(localStorage.getItem("userState"));

  const [username, setUserName] = useState(
    localStorage.getItem("loggedInUser"),
  );

  const [timeoutId, setTimeoutId] = useState(null);
  const signOut = async () => {
    try {
      await Auth.signOut();
      setUserState(false);
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("userState");
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const getUserDetails = async (username, userState) => {
    setUserName(username);
    setUserState(userState);
  };

  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.medium}>
          <Image
            alt="Amplify logo"
            src = {LoginImage}
            width="50%"
            height="50%"
            border="solid 5px #0000a3"
            borderRadius="50%"
          />
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
  };
  
  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      password: {
        label: 'Password:',
        placeholder: 'Enter your Password:',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm Password:',
        order: 1,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
      },
    },
    forgotPassword: {
      username: {
        placeholder: 'Enter your email:',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        label: 'New Label',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please:',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };

  useEffect(() => {
    const resetTimer = () => {
      // Clear any existing timeout
      if (timeoutId) clearTimeout(timeoutId);
      
      // Set a new timeout
      const newTimeoutId = setTimeout(() => {
        handleLogout(); // Trigger the logout after inactivity
      }, AUTO_LOGOUT_TIME);

      setTimeoutId(newTimeoutId);
    };

    const handleLogout = async () => {
      await Auth.signOut(); // Logs the user out
      setUserState(false);
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("userState");
      window.location.reload(); // Optionally reload the page
    };

    // Add event listeners to detect user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);

    // Initial timer setup
    resetTimer();

    // Listen to Hub events
    const listener = async(data) => {
      switch (data.payload.event) {
        case 'signIn':
          console.log('User signed in:', data.payload.data);
          // handleAuthStateChange('authenticated');
          const user = await Auth.currentAuthenticatedUser();
          localStorage.setItem("loggedInUser", user.username);
          localStorage.setItem("userState", true);
          getUserDetails(
            localStorage.getItem("loggedInUser"),
            localStorage.getItem("userState")
          );
          navigate("/company");
          break;
        case 'signOut':
          console.log('User signed out');
          // handleAuthStateChange('signedOut');
          break;
        case 'signIn_failure':
          console.error('Sign in failed:', data.payload.data);
          break;
        case 'sessionExpired':
          console.log('Session expired');
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);

    // Cleanup subscription on unmount
    // return () => Hub.remove('auth', listener);
    return () => {
      // Cleanup event listeners and timeout on unmount
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
    <Authenticator initialState="signIn" hideSignUp={true} components={components}>
      <style>{`input[type="checkbox"]{accent-color:#0000A3;}`}</style>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <IntegrateContextProvider>
          <CompanyContextProvider>
            <Routes>
          
            <Route
              exact
              path="/"
              element={userState ? <Navigate to="/company" replace /> : <Login getUserDetails={getUserDetails} />}
            />
              {userState ? (
                <>
                  <Route
                    exact
                    path="/company"
                    element={<LandingPage user={username} signOut={signOut} />}
                  />
                  <Route
                    path="/company/create-company"
                    element={
                      <CreateCompanyPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/company/update-company"
                    element={
                      <UpdateCompanyPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/company/manage-company"
                    element={
                      <ManageCompaniesPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/company/manage-permissions"
                    element={
                      <ManagePermissionsPage
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/company/create-user"
                    element={
                      <CreateUserPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate"
                    element={
                      <IntegrateLandingPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/select"
                    element={
                      <IntegrateSelection user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/manage-template"
                    element={
                      <ManageIntegrateTemplate
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/integrate/manage-files"
                    element={<ManageFiles user={username} signOut={signOut} />}
                  />
                  <Route
                    path="/integrate/upload-select"
                    element={
                      <IntegrateUploadSelect
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/integrate/eformuploads1"
                    element={
                      <EFormUploadS1 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/eformuploads2"
                    element={
                      <EFormUploadS2 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/uploadtemplates1"
                    element={
                      <UploadTemplateS1 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/uploadtemplates2"
                    element={
                      <UploadTemplateS2 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/uploadtemplates3"
                    element={
                      <UploadTemplateS3 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/createtemplates1"
                    element={
                      <CreateTemplateS1 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/createtemplates2"
                    element={
                      <CreateTemplateS2 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/createtemplates3"
                    element={
                      <CreateTemplateS3 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/integrate/createtemplates4"
                    element={
                      <CreateTemplateS4 user={username} signOut={signOut} />
                    }
                  />
                  {/*
                  <Route
                    path="/report"
                    element={
                      <ReportLandingPage user={username} signOut={signOut} />
                    }
// /reconcile-select
                  ///standardreport-select
                  />
                  <Route
                    path="/report/reconcile-select"
                    element={
                      <ReconcileSelect user={username} signOut={signOut} />
                    }
                  />
*/}
                  <Route
                    path="/report"
                    element={
                      <StandardReportSelect user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/report/reconcile1"
                    element={
                      <ReconcileInstructionsPage
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/report/reconcile2"
                    element={
                      <ReconcilePage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/report/manage-reconciliations"
                    element={
                      <ManageReconciliations
                        user={username}
                        signOut={signOut}
                      />
                    }
                  />
                  <Route
                    path="/report/manage-reports"
                    element={
                      <ManageReports user={username} signOut={signOut} />
                    }
                  />

                  <Route
                    path="/report/runreport"
                    element={<RunReport user={username} signOut={signOut} />}
                  />
                  <Route
                    path="/design"
                    element={
                      <DesignLandingPage user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/design/createbenefits1"
                    element={
                      <CreateBenefitS1 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/design/createbenefits2"
                    element={
                      <CreateBenefitS2 user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/design/benefitinventory"
                    element={
                      <BenefitInventory user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/design/updatebenefitinventory"
                    element={
                      <UpdateBenefit user={username} signOut={signOut} />
                    }
                  />
                  <Route
                    path="/in-development"
                    element={<InDev user={username} signOut={signOut} />}
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <DashboardViewPage user={username} signOut={signOut} />
                    }
                  />
                </>
              ) : (
                <Route path="*" element={<NotFound />} />
              )}
            </Routes>
            {userState && (
              <>
                <div className="pt-1" style={{ height: "5%" }}>
                  <hr className="bg-black h-[1.5px]" />
                  <footer className="w-full mt-2 flex justify-between py-2 text-[#0000A3]">
                    <p className="ml-4 w-1/3 flex justify-start items-center">
                      Feedback
                    </p>
                    <p className="w-1/3 flex justify-center items-center">
                      © 2024, Acropolis Analytics.
                    </p>
                    <div className="flex mr-4 w-1/3 justify-end items-center cursor-pointer">
                      <Link
                        target="_blank"
                        to="https://d63lwo9una5sa.cloudfront.net/Service%20Agreement%20and%20Terms%20of%20Use.pdf"
                        className="ml-4"
                      >
                        Terms
                      </Link>
                      <Link
                        target="_blank"
                        to="https://d63lwo9una5sa.cloudfront.net/Privacy%20Policy.pdf"
                        className="ml-4"
                      >
                        Privacy
                      </Link>
                      <Link
                        target="_blank"
                        to="https://d63lwo9una5sa.cloudfront.net/cookie%20notice.pdf"
                        className="ml-4"
                      >
                        Cookies
                      </Link>
                    </div>
                  </footer>
                </div>
              </>
            )}
          </CompanyContextProvider>
        </IntegrateContextProvider>
      </LocalizationProvider>
      </Authenticator>
    </>
  );
}





// report page reconfig
//
                  // <Route
                  //   path="/report"
                  //   element={
                  //     <ReportLandingPage user={username} signOut={signOut} />
                  //   }
                  // />
                  // <Route
                  //   path="/report/reconcile-select"
                  //   element={
                  //     <ReconcileSelect user={username} signOut={signOut} />
                  //   }
                  // />
                  // <Route
                  //   path="/report/standardreport-select"
                  //   element={
                  //     <StandardReportSelect user={username} signOut={signOut} />
                  //   }
                  // />
                  //


// <Authenticator>
//   {({ signOut, user }) => (
//       <>
//       {
//       console.log(user===null?'no':'yes')
//       }
//       {
//       console.log(currentUserInfo())
//        }
//       <button onClick={signOut}>singOut</button>
//       </>
//   )}
// </Authenticator>

//     </div>
//   </div>
//   <div className="flex">
//     <div className="h-fit">
//       <Sidebar />
//     </div>
//     <div className="w-full h-fit">
//         <Route path="/junk" element={<Test/>} />
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/">Company</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/design">Design</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/integrate">Integrate</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/report">Report</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/dashboard">Dashboard</Link>
//   <Link className="text-xl px-4 hover:border-b-2 border-[#DB2748] py-2" to="/communicate">Communicate</Link>
// <Routes>
// <Route exact path="/" element={} />
// <Route path="/about" element={} />
// </Routes>
//   <h1>Hello {username}</h1>
//   <button onClick={signOut}>Sign out</button>
//
